import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { ColCheckBox, ContainerInputField, InputCustom, LabelInput } from './InputFieldElements';
import IconTitle from '../../IconTitle';
import { ErrorMessage, getIn } from 'formik';
import ErrorMessageCustom from '../../ErrorMessageCustom';


InputField.propTypes = {
	field: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,

	label: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	colorLabel: PropTypes.string,
	iconInput: PropTypes.string,
	colorIcon: PropTypes.string,
	helpText: PropTypes.string,
};

InputField.defaultProps = {
	label: '',
	placeholder: '',
	type: 'text',
	readOnly: false,
	disabled:false,
	colorLabel: 'rgb(122, 122, 122)',
	iconInput: '',
	colorIcon: '',
	helpText:''

}

function InputField({ field, label, type, readOnly, placeholder, colorLabel, iconInput, colorIcon, form , helpText,disabled }) {


	const { name } = field;
	const { errors, touched } = form;
	const showError = getIn(errors, name) && getIn(touched, name);

	return (
		<ContainerInputField >
			{/* Show label || icon */}
			{(label && type !== 'checkbox') && <IconTitle text={label} color={colorLabel} icon={iconInput} colorIcon={colorIcon} />}

			<Row className="position-relative">
				<ColCheckBox type={type} >
					<InputCustom
						name
						{...field}
						type={type}
						readOnly={readOnly}
						disabled={disabled}
						placeholder={placeholder}
						invalid={showError}
					/>
					<ErrorMessage name={name} render={msg => {
						return (
							<ErrorMessageCustom errorMessage={msg} />
						)
					}} />
					{
					helpText && 
					<p style={{color:'#7A7A7A', fontSize:'13px', marginTop:'5px',marginBottom:'0'}}>
						{helpText}
					</p>
				   
					}
					{/* {showError && <ErrorMessageCustom errorMessage={errors[name]} />} */}

				</ColCheckBox>
				{(label && type === 'checkbox')
					&& <Col>
						<LabelInput className="is-invalid" color={colorLabel}>{label}</LabelInput>
					</Col>}
				{/* Show label || icon */}
			</Row>
		</ContainerInputField>
	);
}

export default InputField;