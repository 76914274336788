import { Container } from "reactstrap";
import styled from "styled-components";



export const ContainerAccount = styled(Container)`
		min-height: calc(100vh - 75px);
		padding-top: 25px;
		justify-content: center;
		align-items: start;
		display: flex;
		& >div {
			width: 100%;
			max-width: 640px;
			padding: 50px;
			border-radius: 4px;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
				@media(max-width: 564px){
					padding: 5px
				}
}
`