import { Col, Container } from 'reactstrap';
import styled from 'styled-components';



export const ContainerLogin = styled(Container)`
	min-height: 100vh;
	position: relative;
	& .alert{
		top: 10%;
		left: 10%;
	}
`

export const ImagesLogin = styled(Col)`
	background: url(${props => props.bg});
	min-height:100vh;
	background-size: cover;
	background-position: center center;
	transition: none;
	@media(max-width: 768px){
		display:none;
	}
`


export const FormLogin = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 70px 90px;
	& form{
		gap:20px;
		display:flex;
		flex-direction:column
	}
	@media(max-width: 1160px){
		padding: 30px;
	}

	@media(max-width: 768px){
		min-height: 100vh;
	}
`

