import React from "react";
import PropTypes from "prop-types";
import { TitleWrapper } from "../../Websites/Feeds/FormFeeds/FormFeedElements";
import ButtonCustom from "../ButtonCustom";
import {
    ContainerNavbarBottom,
    TitleNameBottom,
} from "./NavbarBottomHeaderElements";

NavbarBottomHeader.propTypes = {
    title: PropTypes.string,
    titleButton: PropTypes.string,
    linkTo: PropTypes.string,
    clientSelected: PropTypes.string,
    className: PropTypes.string,
    typeButton: PropTypes.string,
};

NavbarBottomHeader.defaultProps = {
    title: "",
    titleButton: "",
    linkTo: "",
    clientSelected: "",
    className: "",
    typeButton: "button",
};

function NavbarBottomHeader(props) {
    const {
        title,
        titleButton,
        linkTo,
        clientSelected,
        className,
        typeButton,
    } = props;
    return (
        <ContainerNavbarBottom className={className}>
            <TitleWrapper>
                <TitleNameBottom>
                    {clientSelected && <h3> {clientSelected} / </h3>}&ensp;
                    <h3> {title}</h3>
                </TitleNameBottom>
                <ButtonCustom
                    type={typeButton}
                    text={titleButton}
                    outline={true}
                    linkTo={linkTo}
                />
            </TitleWrapper>
        </ContainerNavbarBottom>
    );
}

export default NavbarBottomHeader;
