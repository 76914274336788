import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { WebsiteContext } from '../../../contexts/WebsiteProvider';
import { InitContext } from '../../../contexts/InitProvider';
import FormAddWebsite from '../../../components/Websites/FormAddWebsite';
import { LoginContext } from '../../../contexts/LoginProvider';

EditWebsite.propTypes = {

};

function EditWebsite(props) {
	const { id } = useParams();
	const { getWebsiteDetails, websiteDetails } = useContext(WebsiteContext)
	const { getError } = useContext(InitContext);
	const { user } = useContext(LoginContext);


	const getWebsite = async () => {
		const res = await getWebsiteDetails(id);
		if (!res.status) {
			getError(res?.messError);
			setTimeout(() => {
				getError(undefined)
			}, 3000)
		}
	}

	useEffect(() => {
		if (user) {
			getWebsite();
		}
	}, [id, user])

	return (
		<>
			{websiteDetails && <FormAddWebsite data={websiteDetails} edit={true} />}
		</>
	);
}

export default EditWebsite;