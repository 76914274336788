import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router'

AlertLeavePage.propTypes = {
	isOpen: PropTypes.bool,
};

AlertLeavePage.defaultProps = {
	isOpen: false
}

function AlertLeavePage(props) {
	const { isOpen, isGetted } = props

	const setOpen= () =>{	
		return ( isOpen && !isGetted) ? true : false
	}

	return (
		<React.Fragment>
			<Prompt
				when={setOpen()}
				message='You have unsaved changes, are you sure you want to leave?'
			/>
		</React.Fragment>
	);
}

export default AlertLeavePage;