import React, { useContext, useEffect } from 'react';
import { Col } from 'reactstrap';
import { FastField, Form, Formik } from 'formik';
import InputField from '../../Common/FormikCustomField/InputField'
import ButtonCustom from '../../Common/ButtonCustom';
import { ContainerLoginForm, GroupButton } from './LoginFormElements';
import * as Yup from 'yup';
import { LoginContext } from '../../../contexts/LoginProvider';
import { useHistory } from 'react-router-dom';
import { InitContext } from '../../../contexts/InitProvider';

function LoginForm(props) {
	const { login, user } = useContext(LoginContext);
	const history = useHistory();
	const { getError } = useContext(InitContext);


	useEffect(() => {
		if (user) {
			(user.role === "Admin") && history.push("/client");
			(user.role === "Client") && history.push("/");
		}
	}, [user]);

	//Schema
	const loginSchema = Yup.object().shape({
		email: Yup.string()
			.max(50, 'Too Long!')
			.required('Please enter your Username'),
		password: Yup.string()
			.required('Please enter your password')
	})

	const initState = {
		email: '',
		password: '',
		remember: false
	}

	const onLogin = async (values) => {
		const res = await login(values);
		if (!res.status) {
			const errorMess= res.messError.status === 400 ? 'Your license has expired' : 'Wrong Password or UserName';
			await getError(errorMess);
			setTimeout(() => {
				getError(undefined)
			}, 3000)
		}
	}

	return (
		<ContainerLoginForm>
			<Col>
				<Formik
					initialValues={initState}
					onSubmit={(values) => onLogin(values)}
					validationSchema={loginSchema}
					validateOnBlur={false}
					validateOnChange={false}
				>
					{({ isValid, isSubmitting }) => {
						return (
							<Form>
								<FastField
									component={InputField}
									name='email'
									label='Username or E-mail'
									placeholder='Username or E-mail'
								/>
								<FastField
									component={InputField}
									name='password'
									type='password'
									label='Password'
									placeholder='Password'
								/>
								<FastField
									component={InputField}
									type="checkbox"
									name="remember"
									label="Keep me signed in"
								/>
								<GroupButton>
									<Col sm="6" md="12">
										<ButtonCustom type="submit" colorCustom={['#3ba1da', '#44b0ec']} disable={(isValid && isSubmitting) ? true : false} isLoading={(isValid && isSubmitting) ? true : false} />
									</Col>
									<Col sm="6" md="12">
										{/* <ButtonCustom text="Register" colorCustom={['#eeeeee', '#e5e5e5']} colorText="#666666" 
										 linkTo="/register"
										 /> */}
									</Col>
								</GroupButton>
								<ButtonCustom text="Forgot your password?" colorDefault="link" size="sm" colorText="#888" linkTo="/password-reset" />
							</Form>
						)
					}}
				</Formik>
			</Col>
		</ContainerLoginForm>
	);
}

export default LoginForm;