
import axiosClient from '../apis/axiosClient';
import { message } from 'antd';
import { useContext } from 'react';
import { InitContext } from '../contexts/InitProvider'

useApiCRUD.propTypes = {

};

function useApiCRUD() {
	const { addLoading, removeLoading } = useContext(InitContext);

	const apiCreate = async (url, data) => {
		try {
			addLoading();
			const res = await axiosClient.post(url, data);
			if (res.status) {
				message.success({
					content: 'Save Successful',
					className: 'custom-class',
					style: {
						marginTop: '10vh',
					},
					duration: 2
				});
				return { status: true };
			}
		} catch (error) {
			console.log("Error", error)			
			return { status: false, messError: error.response.data.message }
		} finally{
			removeLoading();
		}
	}

	const apiEdit = async (url, dataEdit) => {
		try {
			addLoading();
			const res = await axiosClient.post(url, dataEdit);
			if (res.status) {
				message.success({
					content: 'Edit Success',
					className: 'custom-class',
					style: {
						marginTop: '10vh',
					},
					duration: 2
				});
				return { status: true, data: res.data };
			}
		} catch (error) {
			console.log("Error", error);
			return { status: false, messError: error.response.data.message }
		} finally {
			removeLoading();
		}
	}

	const apiGetList = async (url) => {
		try {
			addLoading();
			const res = await axiosClient.get(url);
			if (res.status) {
				return { status: true, data: res.data }
			}
		} catch (error) {
			console.log("Error", error);
			return { status: false, messError: error.response.data.message }
		} finally {
			removeLoading();
		}
	}

	const apiGetOne = async (url) => {
		try {
			addLoading();
			const res = await axiosClient.get(url);
			if (res.status) {
				return { status: true, data: res.data }
			}
		} catch (error) {
			console.log("Error", error)
			return { status: false, messError: error.response.data.message }
		} finally {
			removeLoading();
		}
	}


	const apiDelete = async (url) => {
		try {
			addLoading();
			const res = await axiosClient.delete(url)
			if (res.status) {
				await message.success({
					content: 'Delete Success',
					className: 'custom-class',
					style: {
						marginTop: '10vh',
					},
					duration: 2
				});
				return { status: true }
			}
		} catch (error) {
			console.log("Error", error)
			return { status: false, messError: error.response.data.message }
		} finally {
			removeLoading();
		}
	}

	return [apiCreate, apiEdit, apiGetList, apiGetOne, apiDelete]
}

export default useApiCRUD;