
import React, { createContext, useState } from 'react';
import axiosClient from '../apis/axiosClient';
import useApiCRUD from '../hooks/useApiCRUD';
import { message } from 'antd';
import { ConverDataEncrypt } from '../utils/ConverDataEncrypt';

export const FeedContext = createContext();
function FeedProvider({ children }) {

	const [feed, setFeed] = useState();
	const [apiCreate, apiEdit, , apiGetOne, ,] = useApiCRUD()

	const addFeed = async (infoFeed) => {
		const url = '/api/feeds';
		const res = await apiCreate(url, infoFeed);
		return res;
	}

	const getFeedDetails = async (id) => {
		const url = `/api/feeds/${id}`;
		const res = await apiGetOne(url);
		if (res?.status) {
			const parseMapping = JSON.parse(res.data.data_mapping);
			parseMapping && Object.assign(res.data, { data_mapping: parseMapping })

			 
			const feedData = { ...res?.data, password: ConverDataEncrypt.decrypted(res?.data?.password) }

			

			const parseMetadataField= {...feedData?.wwa_server, metadata_field: JSON.parse(feedData?.wwa_server?.metadata_field)}

			const finalFeedData= {...feedData,wwa_server:  parseMetadataField}

			const feedClone= {...finalFeedData,downloadadble_products: JSON.parse(finalFeedData?.downloadadble_products)}
	
			setFeed(feedClone);
		}
		return res;
	}

	const editFeed = async (id, infoFeed) => {
		const url = `/api/feeds/${id}`;
		const res = await apiEdit(url, infoFeed);
		return res;
	}

	const testQuery = async (query) => {
		try {
			const url = '/api/wwa-servers/testing'
			const queryBase64 = { ...query, password: ConverDataEncrypt.encrypt(query?.password) }
			const res = await axiosClient.post(url, queryBase64);
			if (res.status) {
				return { status: true, data: res.data }
			}
		} catch (error) {
			return { status: false, messError: error.response.data }
		}
	}

	const importAssets = async (feedId,messSuccess) => {
		if (feedId) {
			const url = `/api/assets/import/${feedId}`;
			try {

			const res = await axiosClient.post(url);
			
			
			if (res.status) {
				message.success({
					content:messSuccess ? messSuccess : 'Import running...',
					className: 'custom-class',
					style: {
						marginTop: '10vh',
					},
					duration: 3
				});
				return { status: true }
			}
							
		} catch (error) {
				return { 
					status: false ,
					messError: error.response.data.message
				}	
		}
			
		}
	}

	const updateAssets = async (feedId) => {
		if (feedId) {
			const url = `/api/assets/update/${feedId}`;
			const res = await axiosClient.post(url);
			if (res.status) {
				message.success({
					content:'Update running...',
					className: 'custom-class',
					style: {
						marginTop: '10vh',
					},
					duration: 3
				});
				return { status: true }
			}
		}
	}

	

	// const deleteFeed = async (id) => {
	// 	const url = `/api/feeds/${id}`;
	// 	const res = await apiDelete(url);
	// 	if (res.status) {
	// 		const cloneFeeds = [...feeds];
	// 		const feedsDeleted= await cloneFeeds.filter(item => item.id !== id);
	// 		await setFeed(feedsDeleted);
	// 	}
	// 	return res;
	// }

	return (
		<FeedContext.Provider value={{ feed, addFeed, getFeedDetails, editFeed, testQuery, importAssets, updateAssets }}>
			{children}
		</FeedContext.Provider>
	);
}

export default FeedProvider;