
import styled from 'styled-components';

export const ContainerNavbar = styled.header`
	background-color: rgb(0, 158, 227);
	min-height: 75px;
	display:flex;
	align-items: center;
	position: fixed;
	z-index: 999;
	width: 100%;
	& p{
		color:rgb(255, 255, 255);
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 0;
    	display: flex;
    	align-items: center;
	}
	& .dropdown-menu >span > a{
		font-size:18px;
	}
`


export const WrapperNavbar = styled.div`
	display: flex;
    justify-content: space-between;
	max-height: 45px;
	align-items: center;

	& .icons-navbar .button_custom{
		margin-bottom: 0;
	}

`


export const MenuNavbarHeader = styled.div`
& div.dropdown{
	max-height: 50px;
}
& .dropdown-menu{
	padding: 10px 20px;
	text-align: center;
	& span {
		padding: 10px 24px;
		border-left-color: white;
		border-right-color: white;	
		border-top-color: white;
		&:last-child:hover,:last-child:active,:last-child:focus{
			border-left-color: white;
			border-right-color: white;	
			border-top-color: white;			
			background: unset;
			color: unset;
		}
		& a{
			color: black;
		}
		&:hover {
		border-bottom: 2.5px solid #009ee3;
		background-color: white;
		transition-delay:0.2;
		cursor: pointer;
		}
	}
}
& ul{
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;

 & > li{
	padding: 0px 10px 0px 10px;
	position: relative;
	& :after{
		content: '';
		position: absolute;
		right:0;
		top:33%;
		height: 18px;
		width:1px;
		background: white;
	}
	@media(max-width: 768px){
		display: none;
	}
	&  > a{
		color: rgb(255, 255, 255);
		font-size: 18px;
		font-weight: 400;		
	}
 }
}
	& .dropdown-menu{
		box-shadow:  2px 8px 23px 3px rgba(0,0,0,0.2);
		margin-top: -1rem;
	}
`