import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FastField, Form, Formik } from "formik";
import IconTitle from "../../Common/IconTitle";
import InputField from "../../Common/FormikCustomField/InputField";
import { TitleWrapper } from "../../Websites/Feeds/FormFeeds/FormFeedElements";
import { ContainerFormClientsHome } from "./FormClientsHomeElement";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ButtonCustom from "../../Common/ButtonCustom";
import { LoginContext } from "../../../contexts/LoginProvider";
import { InitContext } from "../../../contexts/InitProvider";

FormClientsHome.propTypes = {
    data: PropTypes.object,
    onSetPassword: PropTypes.func
};

FormClientsHome.defaultProps = {
    data: {},
    onSetPassword: null
};

function FormClientsHome(props) {
    const initState = {
        name: "",
        address: "",
        fullname: "",
        email: "",
        start_date: "",
        end_date: "",
    };

    const { data, onSetPassword } = props;
    const [infoForm, setInfoForm] = useState(initState);

    useEffect(() => {
        if (data) {
            const dataForm = {
                name: data.name || "",
                address: data.address || "",
                fullname: data.admin.admin_name || "",
                email: data.admin.email || "",
                start_date: data.license[0].start_date || "",
                end_date: data.license[0].end_date || "",
            };
            setInfoForm(dataForm);
        }
    }, [data]);

    const onHandleSetPassword = (email) => {
        if (email) {
            onSetPassword(email);
        }
    };

    return (
        <ContainerFormClientsHome>
            <TitleWrapper>
                <div className="d-flex justify-content-center align-items-baseline block-title-header">
                    <h2 className="text-capitalize">
                        {infoForm && infoForm.name}
                    </h2>
                    <Link to={data && `/edit-client/${data.id}`}>
                        <IconTitle
                            colorIcon="#7A7A7A"
                            icon="pencil-alt"
                            fontSizeIcon="18px"
                        />
                    </Link>
                </div>
                <div className="block-button-header">
                    <Link to={`/client/${data.id}/website`}>
                        <IconTitle
                            colorIcon="#7A7A7A"
                            icon="arrow-alt-circle-right"
                            fontSizeIcon="30px"
                        />
                    </Link>
                </div>
            </TitleWrapper>
            <Formik initialValues={infoForm} enableReinitialize={true}>
                {({ values }) => {
                    return (
                        <Form>
                            <IconTitle
                                text="Company Info"
                                icon="address-card"
                            />
                            <FastField
                                component={InputField}
                                name="name"
                                label="Name"
                                colorLabel="#7A7A7A"
                                readOnly={true}
                            />
                            <FastField
                                component={InputField}
                                name="address"
                                label="Address"
                                colorLabel="#7A7A7A"
                                readOnly={true}
                            />
                            <IconTitle text="Admin" icon="user" />
                            <FastField
                                component={InputField}
                                name="fullname"
                                label="Name"
                                colorLabel="#7A7A7A"
                                readOnly={true}
                            />
                            <Row>
                                <Col sm="6">
                                    <FastField
                                        component={InputField}
                                        name="email"
                                        label="Email"
                                        colorLabel="#7A7A7A"
                                        readOnly={true}
                                    />
                                </Col>
                                <Col
                                    sm="3"
                                    className="d-flex align-items-center justify-content-start"
                                >
                                    <ButtonCustom
                                        size="sm"
                                        text="Send Password"
                                        paddingbutton="6px 20px"
                                        className="mt-2"
                                        onClick={() =>
                                            onHandleSetPassword(values.email)
                                        }
                                    />
                                </Col>
                            </Row>
                            <IconTitle text="License" icon="clock" />
                            <FastField
                                component={InputField}
                                name="start_date"
                                type="date"
                                label="Start Date"
                                colorLabel="#7A7A7A"
                                readOnly={true}
                            />
                            {values.end_date && (
                                <FastField
                                    component={InputField}
                                    name="end_date"
                                    type="date"
                                    label="End Date"
                                    colorLabel="#7A7A7A"
                                    readOnly={true}
                                />
                            )}
                            <IconTitle text="Usage" icon="globe" />
                            <FastField
                                component={InputField}
                                name="websites"
                                label="Websites"
                                colorLabel="#7A7A7A"
                                readOnly={true}
                            />
                            <FastField
                                component={InputField}
                                name="feeds"
                                label="Feeds"
                                colorLabel="#7A7A7A"
                                readOnly={true}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ContainerFormClientsHome>
    );
}

export default FormClientsHome;
