
import axiosClient from "./axiosClient";


const loginApi = {
  getToken: (userInfo) => {
    const url = '/api/login';
    return axiosClient.post(url, userInfo);
  },

  register: (userInfo) => {
    const url = '/api/register';
    return axiosClient.post(url, userInfo);
  },

  getInfoUser: () => {
    const url = '/api/profile';
    return axiosClient.get(url);
  },
  activeUser: (code) => {
    const url = "/api/activate";
    return axiosClient.post(url, code);
  },
  resetPassword: (email) => {
    const url = "/api/reset-password";
    return axiosClient.post(url, email)
  },
  changePassword: (newPassword) => {
    const url = "/api/change-password";
    return axiosClient.post(url, newPassword)
  },
  renewPassword: (newPassword) => {
    const url = "/api/renew-password";
    return axiosClient.post(url, newPassword)
  }
};


export default loginApi;