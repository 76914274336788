
import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import ErrorMessageGlobal from '../../components/Common/ErrorMessageGlobal';
import MainNavbar from '../../components/Navbar/MainNavbar';
import { InitContext } from '../../contexts/InitProvider';
import { ContainerDefaultLayout } from './DefaultLayoutElements';
import LoadingTemplate from '../../components/Common/LoadingTemplate'

function DefaultLayout({ children }) {
	const { error, loading } = useContext(InitContext)

	return (
		<>
			{loading && <LoadingTemplate />}
			<MainNavbar />
			<ContainerDefaultLayout fluid >
				<Row>
					<Col>
						{error && <ErrorMessageGlobal messError={error} />}
						 {children} 					
					</Col>
				</Row>
			</ContainerDefaultLayout>
		</>
	);
}

export default DefaultLayout;