import React, { useContext } from "react";
import { ContainerFormDownloadRenditions } from "../FormDownloadRenditions/DownloadRenditionsElements";
import { TitleWrapper } from "../../Websites/Feeds/FormFeeds/FormFeedElements";
import InputField from "../../Common/FormikCustomField/InputField";
import RadioField from "../../Common/FormikCustomField/RadioField";
import { FastField, Form, Formik } from "formik";
import * as Yup from "yup";
import { Col, Row } from "reactstrap";
import ButtonCustom from "../../Common/ButtonCustom";
import { DownloadRenditionsContext } from "../../../contexts/DownloadRenditionsProvider";
import { useHistory, useParams } from "react-router-dom";
import { InitContext } from "../../../contexts/InitProvider";

function FormAddDownloadRenditions(props) {
    const { addDownloadRenditions } = useContext(DownloadRenditionsContext);
    const { getError } = useContext(InitContext);
    const { clientId } = useParams();
    const history = useHistory();

    const initState = {
        name: "",
        renditions_key: "",
        config: {
            format: "jpg",
            embedMetadata: 0,
            watermarked: 1,
            embedColorProfile: 0,
            quality: 50,
            ppi: "",
            //compression: '',
            scale: "",
            maxWidth: "",
            maxHeight: "",
            background: "#ffffff",
            validFor: 600,
        },
    };

    const renditionsSchema = Yup.object().shape({
        name: Yup.string().required("Name is a required field"),
        renditions_key: Yup.string().required(
            "Renditions Key is a required field"
        ),
        config: Yup.object().shape({
            format: Yup.string().required("Format is a required field"),
            validFor: Yup.number().required("Valid is a required field"),
            quality: Yup.number()
                .min(1, "Must not be less than 0")
                .max(100, "Must not be greater than 100"),
        }),
    });

    const onSaveRenditions = async (values) => {
        const data = { ...values };
        clientId && Object.assign(data, { client_id: clientId });
        if (data?.config?.format === "jpg") data.config.background = "";

        const res = await addDownloadRenditions(data);
        if (res.status) {
            history.goBack();
        } else {
            getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 6000);
        }
    };

    const onChangeConfigFormat = (setFieldValue, e) => {
        setFieldValue("config.format", e.target.value);
        setFieldValue("config.quality", 50);
    };

    return (
        <ContainerFormDownloadRenditions>
            <Formik
                initialValues={initState}
                validationSchema={renditionsSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => onSaveRenditions(values)}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form>
                            <Row>
                                <Col>
                                    <TitleWrapper isEdit={!isSubmitting}>
                                        <h2>Add Download Rendition</h2>
                                    </TitleWrapper>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="name"
                                        label="Name"
                                        placeholder="Name"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="renditions_key"
                                        label="Renditions Key"
                                        placeholder="Renditions Key"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={RadioField}
                                        name="config.format"
                                        label="Format"
                                        colorLabel="#7A7A7A"
                                        onHandleChange={(e) =>
                                            onChangeConfigFormat(
                                                setFieldValue,
                                                e
                                            )
                                        }
                                        option={[
                                            { value: "jpg", name: "jpg" },
                                            //{ value: 'tiff', name: 'tiff' },
                                            { value: "png", name: "png" },
                                        ]}
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={RadioField}
                                        name="config.watermarked"
                                        label="Watermarked"
                                        colorLabel="#7A7A7A"
                                        option={[
                                            { value: 1, name: "yes" },
                                            { value: 0, name: "no" },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    {values.config.format === "jpg" ? (
                                        <FastField
                                            component={RadioField}
                                            name="config.embedMetadata"
                                            label="Embed metadata"
                                            colorLabel="#7A7A7A"
                                            option={[
                                                { value: 1, name: "yes" },
                                                { value: 0, name: "no" },
                                            ]}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={RadioField}
                                        name="config.embedColorProfile"
                                        label="Embed color profile"
                                        colorLabel="#7A7A7A"
                                        option={[
                                            { value: 1, name: "yes" },
                                            { value: 0, name: "no" },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        type="number"
                                        name="config.quality"
                                        label="Quality"
                                        placeholder="Quality"
                                        helpText="JPEG compression. A value from 1 to 100. 100 being the highest quality. 
											50 is recommended for preview images"
                                        colorLabel="#7A7A7A"
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        type="number"
                                        name="config.ppi"
                                        label="PPI"
                                        placeholder="PPI"
                                        colorLabel="#7A7A7A"
                                        helpText="The pixels per inch / dots per inch can be set to any desired value.
										 When not set, the original PPI / DPI value of the asset will be used."
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        type="number"
                                        name="config.maxWidth"
                                        label="Maximum width(px)"
                                        placeholder="Maximum width"
                                        colorLabel="#7A7A7A"
                                        helpText="Sets the maximum width of the preview. The aspect ratio will always remain the same. 
										If the original asset fits within the supplied dimensions, 
										the original width and height will be used for generating the rendition."
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        type="number"
                                        name="config.maxHeight"
                                        label="Maximum height(px)"
                                        placeholder="Maximum height"
                                        colorLabel="#7A7A7A"
                                        helpText="Sets the maximum width of the preview. The aspect ratio will always remain the same.
										 If the original asset fits within the supplied dimensions, 
										 the original width and height will be used for generating the rendition"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        type="number"
                                        name="config.scale"
                                        label="Scale"
                                        placeholder="Scale"
                                        colorLabel="#7A7A7A"
                                        helpText="Scales the width and height of the rendition relative to the original size.
										 The scale is in percentages from 1 to 100. 
										 If used in combination with maxWidth or maxHeight the image will be scaled with a maximum size."
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        type="number"
                                        name="config.validFor"
                                        label="Valid for(minutes)"
                                        placeholder="Valid for"
                                        colorLabel="#7A7A7A"
                                        helpText="Amount of minutes the url is valid, specify -1 to obtain a url which is valid forever"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {values.config.format === "png" && (
                                    <Col sm="12" lg="6">
                                        <FastField
                                            component={InputField}
                                            name="config.background"
                                            type="color"
                                            label="Background"
                                            placeholder="Background"
                                            colorLabel="#7A7A7A"
                                            helpText="Sets the background color (hex color code) for transparent images.
										 If not specified, the output of PNG images will be transparent.”"
                                        />
                                    </Col>
                                )}
                                {/* <Col sm='12' lg='6'>
									<FastField
										component={InputField}
										name='config.compression'
										label='Compression'
										placeholder='Compression'
										colorLabel='#7A7A7A'
									/>
								</Col> */}
                            </Row>
                            <ButtonCustom
                                text="Save"
                                type="submit"
                                outline={true}
                                fullWidth="auto"
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ContainerFormDownloadRenditions>
    );
}

export default FormAddDownloadRenditions;
