import { Modal } from "antd";
import { FastField, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import ButtonCustom from "../ButtonCustom";
import SelectField from "../FormikCustomField/SelectField";
import IconTitle from "../IconTitle";

ModalAddMetadata.propTypes = {
    onClick: PropTypes.func,
    arrayHelpers: PropTypes.object,
    allDataWWA: PropTypes.array,
    listSelectWP: PropTypes.array,
    postType: PropTypes.string,
};

ModalAddMetadata.defaultProps = {
    onClick: null,
    arrayHelpers: null,
    wwaServerId: "",
    allDataWWA: [],
    listSelectWP: [],
    postType: "",
};

function ModalAddMetadata(props) {
    const initState = {
        wwa: "",
        wc: "",
    };

    const { arrayHelpers, wwaServerId, allDataWWA, listSelectWP, postType } =
        props;

    const [visible, setVisible] = useState(false);

    const createListWWA = () => {
        if (wwaServerId && allDataWWA) {
            const optionWWa = allDataWWA.filter(
                (val) => val.id === parseInt(wwaServerId)
            );
            const listSeelctWWA = optionWWa[0]?.metadata_field
                ? optionWWa[0]?.metadata_field?.map((item) =>
                      Object.values(item)
                  )
                : [];

            return listSeelctWWA.join(",  ");
        }
    };

    const createListWC = () => {
        const listWWC= (listSelectWP || []).map((val) => val.value);
        return postType === "product"
            ? listWWC.join(",  ")
            : listWWC
                  .filter(
                      (val) =>
                          val !== "description" &&
                          val !== "short_description"
                  )
                  .join(",  ");
    };

    useEffect(() => {}, [wwaServerId, allDataWWA]);

    const schema = Yup.object().shape({
        wc: Yup.string().required("Woocommerce is a required field"),
    });

    const onAddMoreMetadata = (values) => {
        arrayHelpers.push({
            wwa: "",
            wc: "",
            type_wwa: values.wwa,
            type_wc: values.wc,
        });
        setVisible(false);
    };

    return (
        <div>
            <ButtonCustom
                className="button-add-new"
                text="Add New"
                onClick={() => setVisible(true)}
                outline={true}
                fullWidth="auto"
            />
            <Modal
                title="Create Metadata Mapping"
                centered
                visible={visible}
                onCancel={() => setVisible(false)}
                width={1000}
                footer={null}
            >
                <Formik
                    initialValues={initState}
                    onSubmit={(values) => onAddMoreMetadata(values)}
                    enableReinitialize={true}
                    validationSchema={schema}
                >
                    {({ values }) => {
                        return (
                            <Form>
                                <Row>
                                    <Col sm="6">
                                        <p className="font-weight-bold">
                                            WoodWing Assets
                                        </p>
                                    </Col>
                                    <Col sm="6">
                                        <p className="font-weight-bold">
                                            WordPress
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="5">
                                        <FastField
                                            component={SelectField}
                                            name="wwa"
                                            option={[
                                                {
                                                    name: "WWA default field",
                                                    value: "select",
                                                },
                                                {
                                                    name: "Custom field",
                                                    value: "custom",
                                                },
                                            ]}
                                            readOnly={true}
                                        />
                                        {values.wwa === "select" && (
                                            <span className="font-italic">
                                                {createListWWA()}
                                            </span>
                                        )}
                                    </Col>
                                    <Col
                                        sm="1"
                                        className="iconArray mt-2 align-items-baseline justify-content-center"
                                    >
                                        <IconTitle
                                            icon="long-arrow-alt-right"
                                            fontSizeIcon="24px"
                                            position="center"
                                        />
                                    </Col>
                                    <Col sm="5">
                                        <FastField
                                            component={SelectField}
                                            name="wc"
                                            option={[
                                                {
                                                    name: "Wordpress default fields",
                                                    value: "select",
                                                },
                                                {
                                                    name: "Custom field",
                                                    value: "custom",
                                                },
                                            ]}
                                        />
                                        {values.wc === "select" &&
                                            listSelectWP && (
                                                <span className="font-italic">
                                                    {createListWC()}
                                                </span>
                                            )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        sm="11"
                                        className="d-flex justify-content-end mt-3"
                                    >
                                        <ButtonCustom
                                            text="Add New"
                                            outline={true}
                                            fullWidth="auto"
                                            type="submit"
                                        />
                                        <ButtonCustom
                                            text="Cancel"
                                            outline={true}
                                            fullWidth="auto"
                                            onClick={() => setVisible(false)}
                                            className="ml-3"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </div>
    );
}

export default ModalAddMetadata;
