import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import ErrorMessageGlobal from '../../components/Common/ErrorMessageGlobal';
import LoadingTemplate from '../../components/Common/LoadingTemplate';
import AdminNavbar from '../../components/Navbar/AdminNavbar';
import { InitContext } from '../../contexts/InitProvider';
import { ContainerDefaultLayout } from '../DefaultLayout/DefaultLayoutElements';

function AdminLayout({ children }) {
	const {error,loading}=useContext(InitContext)
	return (
		<>
		{loading && <LoadingTemplate />}
			<AdminNavbar />
			<ContainerDefaultLayout fluid >
				<Row>
					<Col>
					{error && <ErrorMessageGlobal messError={error} />}
						{children}
					</Col>
				</Row>

			</ContainerDefaultLayout>
		</>
	);
}

export default AdminLayout;