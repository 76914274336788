import React, { useContext, useEffect, useState } from 'react';
import { FastField, Form, Formik } from 'formik';
import InputField from '../../Common/FormikCustomField/InputField';
import ButtonCustom from '../../Common/ButtonCustom';
import { userContext } from '../../../contexts/UserProvider';

function UsernameAccount(props) {
	const { userInfo } = useContext(userContext);
	const initState = {
		email: ''
	}
	const [email, setEmail] = useState(initState);

	useEffect(() => {
		setEmail({ email: userInfo?.email || '' })
	}, [userInfo])


	return (
		<Formik
			initialValues={email}
			enableReinitialize={true}
		>
			{() => (
				<Form>
					<FastField
						component={InputField}
						name="email"
						label="Username"
						placeholder="Username"
						readOnly={true}
					/>
					<ButtonCustom text="Update Account" outline={true} fullWidth="auto" />
				</Form>
			)}
		</Formik>
	);
}

export default UsernameAccount;