import styled from 'styled-components';



export const ContainerNavbarBottom = styled.div`
	padding:10px 15px;
	background-color:#F9F9F9;
	margin: 0 -15px;
	${props=> props.className === 'sticky_header' && 'z-index: 99; position: sticky; top: 75px; max-height: 100px;' }
	& button {
		background: #fff;
		/* & :hover{
			background: 
		} */
	}
	/* & h2{
		color: rgb(0, 158, 227);
		font-size: 2rem;
		font-weight: 400;
		margin-bottom: 0
	} */
`

export const TitleNameBottom = styled.div`
	display: flex;
	justify-content: center;
	align-items: baseline;
	& h3 {
		color: rgb(0, 158, 227);
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 0;
		text-transform: capitalize;
	}
	& h6{
		margin-bottom:0;
		padding-left:2rem;
		text-transform: capitalize;
		color: red;
		font-size: 18px;
		font-weight: 400;
	}

	@media(max-width: 768px){
		justify-content: flex-start !important;
	}

`