import AdminLayout from './layouts/AdminLayout';
import DefaultLayout from './layouts/DefaultLayout';
import LayoutNotHeader from './layouts/LayoutNotHeader';
import LoginLayout from './layouts/LoginLayout';
import Account from './pages/Account';
import EditFormAccount from './pages/Account/EditFormAccount';
import ActivePage from './pages/ActivePage';
import AddNewClients from './pages/Client/AddNewClients';
import ClientsHome from './pages/Client/ClientsHome';
import EditClient from './pages/Client/EditClient';
import AddDownloadRenditions from './pages/DownloadRenditions/AddDownloadRenditions';
import HomeRenditions from './pages/DownloadRenditions/HomeRenditions'
import AddFeed from './pages/Feed/AddFeed';
import EditFeed from './pages/Feed/EditFeed';
import Login from './pages/Login';
import PasswordReset from './pages/PasswordReset';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import AddWebsite from './pages/Websites/AddWebsite';
import EditWebsite from './pages/Websites/EditWebsite';
import Home from './pages/Websites/Home';
import AddWoodWingAssets from './pages/WoodwingAssets/AddWoodWingAssets';
import HomeWoodWingAssets from './pages/WoodwingAssets/HomeWoodWingAssets';

export default [
  {
    path: '/login',
    exact: true,
    layout: LoginLayout,
    component: Login,
  },
  // {
  //   path: '/register',
  //   exact: true,
  //   layout: LayoutNotHeader,
  //   component: Register,
  // },
  {
    path: '/password-reset',
    exact: true,
    layout: LoginLayout,
    component: PasswordReset,
  },
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: Home,
  },
  {
    path: '/add-website',
    exact: true,
    layout: DefaultLayout,
    component: AddWebsite,
  },

  {
    path: '/edit-website/:id',
    exact: true,
    layout: DefaultLayout,
    component: EditWebsite,
  },
 
  {
    path: '/add-website/client/:clientId',
    exact: true,
    layout: DefaultLayout,
    component: AddWebsite,
    
  },
  {
    path: '/woodwing-assets',
    exact: true,
    layout: DefaultLayout,
    component:HomeWoodWingAssets ,
  },
  {
    path: '/add-assets-server',
    exact: true,
    layout: DefaultLayout,
    component: AddWoodWingAssets,
  },
  {
    path: '/download-renditions',
    exact: true,
    layout: DefaultLayout,
    component: HomeRenditions,
  },

  {
    path: '/add-download-renditions',
    exact: true,
    layout: DefaultLayout,
    component: AddDownloadRenditions,
  },
  {
    path: '/add-feed/:websiteId',
    exact: true,
    layout: DefaultLayout,
    component: AddFeed,
  },
  {
    path: '/edit-feed/:id',
    exact: true,
    layout: DefaultLayout,
    component: EditFeed,
  },
  {
    path: '/user/activate/:activeCode',
    exact: true,
    layout: LayoutNotHeader,
    component: ActivePage,
  },
  {
    path: '/user/reset/:activeCode',
    exact: true,
    layout: LayoutNotHeader,
    component: ResetPassword,
  },
  {
    path: '/account/general',
    exact: true,
    layout: DefaultLayout,
    component: Account,
    isGlobal:true
  },
  {
    path: '/profile/edit-profile',
    exact: true,
    layout: DefaultLayout,
    component: EditFormAccount,
    isGlobal:true
  },


  
  //Route Admin
  {
    path: '/client',
    exact: true,
    layout: AdminLayout,
    component: ClientsHome,
    isAdmin: true
  },
  {
    path: '/add-client',
    exact: true,
    layout: AdminLayout,
    component: AddNewClients,
    isAdmin: true
  },
  {
    path: '/edit-client/:id',
    exact: true,
    layout: AdminLayout,
    component: EditClient,
    isAdmin: true
  },
  {
    path: '/client/:clientId/website',
    exact: true,
    layout: DefaultLayout,
    component: Home,
    isAdmin: true
  },
  {
    path: '/client/:clientId/add-website',
    exact: true,
    layout: DefaultLayout,
    component: AddWebsite,
    isAdmin: true
  },
  {
    path: '/client/:clientId/edit-website/:id',
    exact: true,
    layout: DefaultLayout,
    component: EditWebsite,
    isAdmin: true
  },
  
  {
    path: '/client/:clientId/download-renditions',
    exact: true,
    layout: DefaultLayout,
    component: HomeRenditions,
    isAdmin: true
  },
  {
    path: '/client/:clientId/add-download-renditions',
    exact: true,
    layout: DefaultLayout,
    component: AddDownloadRenditions,
    isAdmin: true
  },
  {
    path: '/client/:clientId/woodwing-assets',
    exact: true,
    layout: DefaultLayout,
    component:HomeWoodWingAssets ,
  },
  {
    path: '/client/:clientId/add-assets-server',
    exact: true,
    layout: DefaultLayout,
    component: AddWoodWingAssets,
  },
  {
    path: '/client/:clientId/add-feed/:websiteId',
    exact: true,
    layout: DefaultLayout,
    component: AddFeed,
    isAdmin: true
  },
  {
    path: '/client/:clientId/edit-feed/:id',
    exact: true,
    layout: DefaultLayout,
    component: EditFeed,
    isAdmin: true
  },
 

];
