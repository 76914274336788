import { Button } from 'reactstrap';
import styled from 'styled-components';




export const ButtonPrimary = styled(Button)`
	/* padding:16px 20px; */
	padding: ${props => props.paddingbutton};

	${props => props.disabled && `cursor:no-drop`};
	width:${props => (props.width ? props.width : 'auto')};
	${props => props.colorcustom ? `background: ${props.colorcustom[0]}` : null};
	${props => props.colorcustom ? `border-color: ${props.colorcustom[0]}` : null};

	${props => props.colorcustom ? `&:hover {
		background: ${props.colorcustom[1]} ;
		border-color: ${props.colorcustom[1]}
  }` : null}
  	&:hover > a >span{
		color: white;
	  }
`;


export const TextButton = styled.span`
	color: ${props => props.colortext && props.colortext};
	font-size: 15px;
	font-weight: 500;
	
`