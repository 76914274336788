import styled from 'styled-components';

export const ContainerWwAssets = styled.div`
  border: 1px solid #7a7a7a;
  padding: 25px;
  border-radius: 4px;
  margin-bottom: 20px;
  & button {
    margin: 10px 0;
  }

  & .delete-icon {
    padding-top: 35px;
  }
  /* & input {
    width: 50%;
  } */

  @media (max-width: 768px) {
    & input {
      width: 100%;
    }
  }
`;


export const TitleWwAssets = styled.div`
  display: flex;
  justify-content: space-between;
  & button{
    flex-basis: 101.93px;
  }
`
