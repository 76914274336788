import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FastField, Form, Formik } from "formik";
import InputField from "../../Common/FormikCustomField/InputField";
import IconTitle from "../../Common/IconTitle";
import ButtonCustom from "../../Common/ButtonCustom";
import { ContainerFormGlobal } from "../../../globalStyles";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import RadioField from "../../Common/FormikCustomField/RadioField";
import { WebsiteContext } from "../../../contexts/WebsiteProvider";
import { InitContext } from "../../../contexts/InitProvider";
import AlertLeavePage from "../../Common/AlertLeavePage";
import { Switch } from "antd";
import { Col, Row } from "reactstrap";

FormAddWebsite.propTypes = {
    data: PropTypes.object,
    edit: PropTypes.bool,
    client_id: PropTypes.string,
};

FormAddWebsite.defaultProps = {
    data: {},
    edit: false,
    client_id: "",
};

function FormAddWebsite(props) {
    const initState = {
        name: "",
        site_url: "",
        status: "",
        consumer_key: "",
        consumer_secret: "",
        application_password_status: false,
        woo_api_key_status: false,
        username_application: "",
        password_application: "",
    };

    const history = useHistory();
    const { data, edit, client_id } = props;
    const [dataWebsite, setDataWebsite] = useState(initState);
    const { getError } = useContext(InitContext);
    const { addWebsite, editWebsite } = useContext(WebsiteContext);
    const [isGetted, setIsGetted] = useState(false);

    useEffect(() => {
        if (data && Object.keys(data).length !== 0) {
            const website = {
                name: data.name || "",
                site_url: data.site_url || "",
                status: data.status || "",
                client_id: data.client_id || "",
                consumer_key: data.consumer_key || "",
                consumer_secret: data.consumer_secret || "",
                application_password_status:
                    data.application_password_status === 1 ? true : false,
                woo_api_key_status:
                    data.woo_api_key_status === 1 ? true : false,
                username_application: data.username_application || "",
                password_application: data.password_application || "",
            };
            setDataWebsite(website);
        }
    }, [data]);

    //Validate form Add Client
    const websiteSchema = Yup.object().shape(
        {
            name: Yup.string().required("Company name is required field"),
            site_url: Yup.string().required("Site Url is required field"),
            status: Yup.string().required("Status is required field"),

            consumer_key: Yup.string().when("woo_api_key_status", {
                is: true, // alternatively: (val) => val == true
                then: Yup.string().required("Consumer Key is required field"),
            }),
            consumer_secret: Yup.string().when("woo_api_key_status", {
                is: true, // alternatively: (val) => val == true
                then: Yup.string().required(
                    "Consumer Secret is required field"
                ),
            }),
            woo_api_key_status: Yup.boolean().when(
                "application_password_status",
                {
                    is: false,
                    then: Yup.boolean().oneOf(
                        [true],
                        "At least one of these is required"
                    ),
                }
            ),
            application_password_status: Yup.boolean().when(
                "woo_api_key_status",
                {
                    is: false,
                    then: Yup.boolean().oneOf(
                        [true],
                        "At least one of these is required"
                    ),
                }
            ),
            username_application: Yup.string().when(
                "application_password_status",
                {
                    is: true, // alternatively: (val) => val == true
                    then: Yup.string().required(),
                }
            ),
            password_application: Yup.string().when(
                "application_password_status",
                {
                    is: true, // alternatively: (val) => val == true
                    then: Yup.string().required(),
                }
            ),
        },
        ["application_password_status", "woo_api_key_status"]
    );

    //Action add new website
    const onAddWebsite = async (values) => {
        let data = client_id
            ? Object.assign(values, { client_id: client_id })
            : values;
        const res = await addWebsite(data);
        if (res.status) {
            setIsGetted(true);
            history.goBack();
        } else {
            getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 3000);
        }
    };

    //Action edit website
    const onEditWebsite = async (id, values) => {
        console.log("values", values);
        const res = await editWebsite(id, values);
        if (res.status) {
            setIsGetted(true);
            history.goBack();
        } else {
            getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 3000);
        }
    };

    //
    const onChangeSwitchWoo = (checked, setFieldValue) => {
        //const valueSwitch= checked ? 1 : 0;
        setFieldValue("woo_api_key_status", checked);
        if (!checked) {
            setFieldValue("consumer_key", "");
            setFieldValue("consumer_secret", "");
        }
    };

    const onChangeSwitchApplication = (checked, setFieldValue) => {
        //const valueSwitch= checked ? 1 : 0;
        setFieldValue("application_password_status", checked);
        if (!checked) {
            setFieldValue("username_application", "");
            setFieldValue("password_application", "");
        }
    };

    return (
        <ContainerFormGlobal>
            <IconTitle
                text={edit ? "Edit Website" : "Add New Website"}
                fontSizeText="2rem"
            />
            <Formik
                initialValues={dataWebsite}
                validationSchema={websiteSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
                onSubmit={(values) =>
                    edit && data.id
                        ? onEditWebsite(data.id, values)
                        : onAddWebsite(values)
                }
            >
                {({ isValid, isSubmitting, dirty, setFieldValue, values }) => {
                    return (
                        <Form>
                            <AlertLeavePage
                                isOpen={dirty}
                                isGetted={isGetted}
                            />
                            <Row>
                                <Col sm="5">
                                    <IconTitle
                                        text="Company Info"
                                        icon="globe"
                                    />
                                    <FastField
                                        component={InputField}
                                        name="name"
                                        label="Name"
                                        placeholder="Company Name"
                                        colorLabel="#7A7A7A"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="5">
                                    <FastField
                                        component={InputField}
                                        name="site_url"
                                        label="Site Url"
                                        placeholder="Site Url"
                                        colorLabel="#7A7A7A"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="5">
                                    <FastField
                                        component={RadioField}
                                        name="status"
                                        label="Status"
                                        placeholder="status"
                                        colorLabel="#7A7A7A"
                                        option={[
                                            {
                                                value: "Development",
                                                name: "Development",
                                            },
                                            {
                                                value: "Production",
                                                name: "Production",
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>

                            <Row style={{ minHeight: "185px" }}>
                                <Col sm="5">
                                    <IconTitle
                                        text="Application Password"
                                        icon="cog"
                                    />
                                    <IconTitle
                                        text="Required for creating posts and custom posty types"
                                        color="#7A7A7A"
                                        className="mt-3"
                                    />
                                    <Switch
                                        defaultChecked
                                        onChange={(e) =>
                                            onChangeSwitchApplication(
                                                e,
                                                setFieldValue
                                            )
                                        }
                                        checked={
                                            values.application_password_status ||
                                            false
                                        }
                                    />
                                    <FastField
                                        component={RadioField}
                                        name="application_password_status"
                                        styleRadio={{ display: "none" }}
                                        colorLabel="#7A7A7A"
                                        option={[
                                            {
                                                value: 1,
                                                name: "true",
                                            },
                                            { value: 0, name: "false" },
                                        ]}
                                    />
                                </Col>
                                {values.application_password_status && (
                                    <Col sm="7">
                                        <FastField
                                            component={InputField}
                                            name="username_application"
                                            label="Username"
                                            placeholder="Username"
                                            colorLabel="#7A7A7A"
                                        />
                                        <FastField
                                            component={InputField}
                                            name="password_application"
                                            label="Application Password"
                                            placeholder="Application Password"
                                            colorLabel="#7A7A7A"
                                        />
                                    </Col>
                                )}
                            </Row>

                            <Row style={{ minHeight: "185px" }}>
                                <Col sm="5">
                                    <IconTitle
                                        text="WooCommerce API keys"
                                        icon="cog"
                                    />
                                    <IconTitle
                                        text="Required for creating Woocomerce products"
                                        color="#7A7A7A"
                                        className="mt-3"
                                    />
                                    <Switch
                                        defaultChecked
                                        onChange={(e) =>
                                            onChangeSwitchWoo(e, setFieldValue)
                                        }
                                        checked={
                                            values.woo_api_key_status || false
                                        }
                                    />
                                    <FastField
                                        component={RadioField}
                                        name="woo_api_key_status"
                                        styleRadio={{ display: "none" }}
                                        colorLabel="#7A7A7A"
                                        option={[
                                            {
                                                value: 1,
                                                name: "true",
                                            },
                                            { value: 0, name: "false" },
                                        ]}
                                    />
                                </Col>
                                {values.woo_api_key_status && (
                                    <Col sm="7">
                                        <FastField
                                            component={InputField}
                                            name="consumer_key"
                                            label="Consumer Key"
                                            placeholder="Consumer Key"
                                            colorLabel="#7A7A7A"
                                        />
                                        <FastField
                                            component={InputField}
                                            name="consumer_secret"
                                            label="Consumer Secret"
                                            placeholder="Consumer Secret"
                                            colorLabel="#7A7A7A"
                                        />
                                    </Col>
                                )}
                            </Row>
                            <ButtonCustom
                                text="Save"
                                outline={true}
                                type="submit"
                                fullWidth="auto"
                                disable={isValid && isSubmitting ? true : false}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ContainerFormGlobal>
    );
}

export default FormAddWebsite;
