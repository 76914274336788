import { Modal } from "antd";
import styled from "styled-components";


export const CodeWrapper = styled.pre`
	overflow-y: auto;
	height: 500px;
	white-space: pre-wrap;
	color: white;
	background: #343A40;
	border-radius: 4px;
	border-bottom-left-radius: unset;
	border-bottom-right-radius: unset;
	margin-bottom: 0;
	font-size: 12px;
	padding: 12px;
`

export const ModalQuery = styled(Modal)`
	& .ant-modal-title{
		color: white;
		font-size: 20px;
	}
	& .ant-modal-body{
		padding: 10px;
	}
	& .ant-modal-header{
		background: #009ee3;
	}
	& .ant-modal-wrap{
		  background: rgba(0,0,0,0.7);
	}
`

export const ContainerModalQuery = styled.div`
	/* 0, 158, 227 */
`