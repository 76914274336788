import React from 'react';
import ListFormAccount from '../../components/AccountForm/ListFormAccount';
import { ContainerAccount } from './AccountElements';

function Account(props) {
	return (
		<ContainerAccount>		
			<div>
				<ListFormAccount />
			</div>
		</ContainerAccount>
	);
}

export default Account;