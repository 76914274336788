
import { Modal } from "reactstrap";
import styled from "styled-components";




export const ModalContainer = styled(Modal)`
& .modal-title{
	text-transform: capitalize;
}
& .modal-body{
	font-size: 20px;
    text-align: center;
    //min-height: 200px;
    display: flex;
    align-items: center;
}
`