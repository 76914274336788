import { message } from 'antd';
import React, { createContext, useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import loginApi from '../apis/loginApi';
import useApiCRUD from '../hooks/useApiCRUD';
import { ConverDataEncrypt } from '../utils/ConverDataEncrypt';
import { LoginContext } from './LoginProvider';

export const userContext = createContext();

function UserProvider({ children }) {
	const { user } = useContext(LoginContext);
	const [userInfo, setUserInfo] = useState({});
	const [, apiEdit, ,] = useApiCRUD()


	useEffect(() => {
		if (user) {
			setUserInfo(user);
		}
	}, [user])


	const changeInfoUser = async (id, infoUser) => {
		try {
			const url = `/api/users/${id}`;
			const res = await apiEdit(url, infoUser);
			if (res?.status) {
				setUserInfo(res?.data)
				return { status: true }
			}
		} catch (error) {
			console.log("Error", error)
			return { status: false }
		}
	}


	const changePassword = async (value) => {
		try {
			const newPassword= {
				current_password: ConverDataEncrypt.encrypt(value?.current_password),
				new_password: ConverDataEncrypt.encrypt(value?.new_password)
			}
			const res = await loginApi.changePassword(newPassword);
			if (res.status) {
				message.success({
					content: 'Edit Success',
					className: 'custom-class',
					style: {
						marginTop: '10vh',
					},
					duration: 2
				});
				return { status: false, data: res.data }
			}
		} catch (error) {
			console.log("Error", error)
			return { status: false, messError: error.response.data.message }
		}
	}


	return (
		<userContext.Provider value={{ userInfo, changeInfoUser, changePassword }}>
			{children}
		</userContext.Provider>
	);
}

export default UserProvider;
