import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonCustom from '../../Common/ButtonCustom';
import { TitleWrapper } from '../Feeds/FormFeeds/FormFeedElements'
import IconTitle from '../../Common/IconTitle';
import { FastField, Form, Formik } from 'formik';
import InputField from '../../Common/FormikCustomField/InputField';
import RadioField from '../../Common/FormikCustomField/RadioField';
import ListFeeds from '../Feeds/ListFeeds';
import { ButtonFormWebsite, ContainerFormWebsite } from './FormWebsitesElements';
import { useParams } from 'react-router-dom';

FormWebSites.propTypes = {
	data: PropTypes.object,
};

FormWebSites.defaultProps = {
	data: {}
}

function FormWebSites(props) {

	const initState = {
		url: '',
		status: '',
		// consumer_key:'',
		// consumer_secret:''
	}
	const { clientId } = useParams();
	const { data } = props;
	const [website, setWebsite] = useState(initState)

	useEffect(() => {
		if (data) {
			const dataWebsite = {
				url: data.site_url,
				status: data.status,
				// consumer_key: data.consumer_key,
				// consumer_secret: data.consumer_secret
			}
			setWebsite(dataWebsite)
		}
	}, [data])

	return (
		<ContainerFormWebsite>
			<TitleWrapper>
				<h2 style={{fontSize: '30px'}}>
					{data && data.name}
				</h2>
				<ButtonCustom colorDefault="primary" text="Edit" outline={true} linkTo={clientId ? `/client/${clientId}/edit-website/${data.id}` : `/edit-website/${data.id}`} />
			</TitleWrapper>
			<IconTitle text="Website" icon="globe" fontSizeText="20px" fontSizeIcon="14px" />

			<Formik
				initialValues={website}
				enableReinitialize={true}
			>
				{() => {
					return (
						<Form>
							<FastField
								component={InputField}
								name="url"
								label="URL"
								colorLabel="rgb(122, 122, 122)"
								readOnly={true}
							/>
							{/* <FastField
								component={InputField}
								name="consumer_key"
								label="Consumer Key"
								colorLabel="rgb(122, 122, 122)"
								readOnly={true}
							/>
							<FastField
								component={InputField}
								name="consumer_secret"
								label="Consumer Secret"
								colorLabel="rgb(122, 122, 122)"
								readOnly={true}
							/> */}
							<FastField
								component={RadioField}
								name="status"
								label="Status"
								option={[{ value: 'Development', name: 'Development' }, { value: 'Production', name: 'Production' }]}
								colorLabel="rgb(122, 122, 122)"
								readOnly={true}
								disable={true}
							/>
						</Form>
					)
				}}
			</Formik>
			{(data && data.feeds) && <ListFeeds data={data.feeds} clientId={clientId || null} />}

			<ButtonFormWebsite colorDefault="primary" text="Add Feed" outline={true} fullWidth="auto" linkTo={clientId ?`/client/${clientId}/add-feed/${data.id}` : `/add-feed/${data.id}`} />
		</ContainerFormWebsite>
	);
}

export default FormWebSites;