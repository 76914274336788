import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Col,
    Row,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Collapse,
} from "reactstrap";
import "./form.css";
import classnames from "classnames";

ListFormWebsites.propTypes = {
    data: PropTypes.array,
    childComponent: PropTypes.func,
    isPageWebsite: PropTypes.bool,
};

ListFormWebsites.defaultProps = {
    data: [],
    childComponent: {},
    isPageWebsite: false,
};

function ListFormWebsites(props) {
    const { data, childComponent, isPageWebsite } = props;
    const [activeTab, setActiveTab] = useState(0);
    const [isOpen, setIsOpen] = useState(activeTab);
    const toggle = (idx) => {
        setActiveTab(idx);
    };

    const toogleCollapse = (idx) => {
        setIsOpen(idx);
    };

    const checkExpiredLicense = (item) => {
        if (isPageWebsite) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            const endDate = item?.license[0]?.end_date
                ? new Date(item?.license[0]?.end_date)
                : "";

            if (endDate && currentDate.getTime() > endDate?.getTime()) {
                return <span style={{ color: "#f39c12" }}>&#x2022; </span>;
            }
        }
    };
    return (
        <div className="form-home-client">
            <Row>
                <Col className="form-home-client__col" lg="3" sm="12">
                    <Nav tabs className="form-home-client__tabs">
                        {data &&
                            data.map((item, idx) => {
                                return (
                                    <NavItem
                                        className="form-home-client__tabs-child"
                                        key={idx}
                                    >
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === idx,
                                            })}
                                            onClick={() => {
                                                toggle(idx);
                                            }}
                                        >
                                            {item && checkExpiredLicense(item)}
                                            {item && item.name}
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                    </Nav>
                </Col>
                <Col lg="9" sm="12" className="form-home-client__col">
                    <TabContent
                        activeTab={activeTab}
                        className="form-home-client__content"
                    >
                        {data &&
                            data.map((item, idx) => {
                                return (
                                    <TabPane
                                        tabId={idx}
                                        className="form-home-client__content-child"
                                        key={idx}
                                    >
                                        <Row>
                                            <Col sm="12">
                                                <h4
                                                    onClick={() =>
                                                        toogleCollapse(idx)
                                                    }
                                                    className={
                                                        idx === isOpen
                                                            ? "active-collapse"
                                                            : ""
                                                    }
                                                >
                                                    {item && item.name}
                                                </h4>
                                                <Collapse
                                                    isOpen={idx === isOpen}
                                                >
                                                    {childComponent(item)}
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                );
                            })}
                    </TabContent>
                </Col>
            </Row>
        </div>
    );
}

export default ListFormWebsites;
