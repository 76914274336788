import React, { useContext } from 'react';

import { ContainerNavbar, MenuNavbarHeader, WrapperNavbar } from '../MainNavbar/NavbarElements'
import { Container } from 'reactstrap';
import IconTitle from '../../Common/IconTitle';
import { Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { LoginContext } from '../../../contexts/LoginProvider';


function AdminNavbar(props) {
	const history = useHistory();
	const { user, signOut, token } = useContext(LoginContext)
	const url = useLocation();

	const onSignOut = () => {
		signOut();
		history.push("/login");
	}

	if (!token) {
		return <Redirect to="/login" />
	}
	return (
		<ContainerNavbar fluid>
			<Container fluid>
				<WrapperNavbar>
					<div>
						{url.pathname !== '/client' ?
							<Link to="/client"><IconTitle text="Clients" icon="arrow-alt-circle-left" color="white" colorIcon="white" fontSizeIcon="30px" /></Link> : <p>Welcome, {user && user.fullname}</p>
						}
					</div>
					<MenuNavbarHeader>
						<Nav className="ml-auto" navbar>

							<UncontrolledDropdown setActiveFromChild>
								<DropdownToggle tag="a" className="nav-link">
									<IconTitle icon="user-circle" colorIcon="white" fontSizeIcon="2rem" />
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem tag="span"><Link to="/client">Clients</Link></DropdownItem>
									<DropdownItem tag="span"><Link to="/account/general">My Account </Link> </DropdownItem>
									<DropdownItem tag="span" onClick={onSignOut} >Sign out</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</MenuNavbarHeader>
				</WrapperNavbar>
			</Container>
		</ContainerNavbar>
	);
}
export default React.memo(AdminNavbar);