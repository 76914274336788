import { Container } from "reactstrap";
import styled from "styled-components";


export const ContainerResetPassword = styled(Container)`
	min-height: 100vh;
	justify-content: center;
    align-items: center;
    display: flex;
& >div {
	width: 100%;
	max-width: 640px;
    padding: 50px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
	@media(max-width: 564px){
		padding: 5px
	}
}
& form {
	padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
	& .animate + label{
		font-weight: bold
	}
	@media(max-width: 564px){
		padding: 25px;
	}
}
`