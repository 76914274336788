import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary, TextButton } from './ButtonCustomElement'
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';




ButtonCustom.propTypes = {
	outline: PropTypes.bool,
	colorDefault: PropTypes.string,
	text: PropTypes.string,
	size: PropTypes.string,
	onClick: PropTypes.func,
	fullWidth: PropTypes.string,
	colorCustom: PropTypes.array,
	colorText: PropTypes.string,
	type: PropTypes.string,
	linkTo: PropTypes.string,
	disable: PropTypes.bool,
	isLoading: PropTypes.bool,
	className: PropTypes.string,
	paddingbutton: PropTypes.string
};

ButtonCustom.defaultProps = {
	outline: false,
	colorDefault: 'primary',
	text: 'Login',
	size: 'sm',
	onClick: null,
	fullWidth: '100%',
	colorCustom: [],
	colorText: '',
	type: 'button',
	linkTo: '',
	disable: false,
	isLoading: false,
	className: '',
	paddingbutton: '12px 30px'
}

function ButtonCustom({ outline, text, colorDefault, size, onClick, fullWidth, colorCustom, colorText, type, linkTo, disable, isLoading, className, paddingbutton }) {

	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	

	return (
		<div>
			{ linkTo ?
				<Link to={linkTo}>
					<ButtonPrimary paddingbutton={paddingbutton}  className={className} type={type} outline={outline} color={colorDefault} size={size} onClick={onClick} width={fullWidth} colorcustom={colorCustom} disabled={disable} >
						<TextButton colortext={colorText}>{text}</TextButton>
					</ButtonPrimary>
				</Link> :
				<ButtonPrimary paddingbutton={paddingbutton} className={className} type={type} outline={outline} color={colorDefault} size={size} onClick={onClick} width={fullWidth} colorcustom={colorCustom} disabled={disable} >
					<TextButton colortext={colorText}>{text}{" "}{isLoading && antIcon}</TextButton>
				</ButtonPrimary>
			}
		</div>
	);
}

export default ButtonCustom;