import { Container, Row } from 'reactstrap';
import styled from 'styled-components';



export const ContainerRegisterForm = styled(Container)`
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    margin: 0% 10% 5% 10%;
    padding: 40px;
    max-width: 912px;

	& form {
		display:flex;
		flex-direction:column;
		gap: 20px;

		& .button_custom label {
			font-weight: 600;
		}

		& .register-btn{
			@media(max-width: 576px){
				margin-bottom: 1rem;
			}
		}
	}

	& p {
		cursor: pointer;
		color: rgb(204, 51, 102);

		&:hover{
			color:#336;
		}
	}
`

export const ButtonGroup = styled(Row)`
	& div:last-child button{
		box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
	}
`