import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { WebsiteContext } from '../../../contexts/WebsiteProvider';
import { LoginContext } from '../../../contexts/LoginProvider';
import { useHistory, useParams } from 'react-router-dom';
import { InitContext } from '../../../contexts/InitProvider';
import NavbarBottomHeader from '../../../components/Common/NavbarBottomHeader';
import ListFormWebsites from '../../../components/Websites/ListFormWebsites';
import FormWebSites from '../../../components/Websites/FormWebsites';

function Home(props) {
	const { clientId } = useParams();
	const { getListWebsite, websites } = useContext(WebsiteContext)
	const { getError } = useContext(InitContext)
	const { token, user } = useContext(LoginContext)
	const history = useHistory();

	const getData = async (clientId) => {		
			const res = await getListWebsite(clientId)
			if (!res.status) {
				getError(res?.messError);
				setTimeout(() => {
					getError(undefined)
				}, 3000);
			}
	}

	useEffect(() => {
		if (token && user) {
			(user.role === "Admin" && !clientId) ? history.push("/client") : getData(clientId);
		}
	}, [token, clientId, user])

	return (
		<Row>
			<Col sm="12">
				<NavbarBottomHeader className='sticky_header' clientSelected={(websites) ? websites[0]?.client?.name : ''} title="Websites" titleButton="Add New Websites" linkTo={clientId ? `/client/${clientId}/add-website` : "/add-website"} />
				<ListFormWebsites data={websites} childComponent={(item) => <FormWebSites data={item} />} />
			</Col>
		</Row>
	);
}

export default React.memo(Home);