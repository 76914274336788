import axios from 'axios';
import queryString from 'query-string';
import config from '../config';

const axiosClient = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});


export default axiosClient;
