import { FastField, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import { InitContext } from "../../../contexts/InitProvider";
import { WoodWingAssetsContext } from "../../../contexts/WoodWingAssetsProvider";
import { ContainerFormGlobal } from "../../../globalStyles";
import AlertLeavePage from "../../Common/AlertLeavePage";
import ButtonCustom from "../../Common/ButtonCustom";
import InputField from "../../Common/FormikCustomField/InputField";
import IconTitle from "../../Common/IconTitle";

FormAddWoodWingAsset.propTypes = {
    clientId: PropTypes.string,
};

FormAddWoodWingAsset.defaultProps = {
    clientId: "",
};

function FormAddWoodWingAsset(props) {
    const { addWoodWingAsset } = useContext(WoodWingAssetsContext);
    const { getError } = useContext(InitContext);
    const [isGetted, setIsGetted] = useState(false);
    const history = useHistory();
    const { clientId } = props;

    const initState = {
        name: "",
        url: "",
        metadata_field: [],
    };

    const schema = Yup.object().shape({
        name: Yup.string().required("Server Name is required field"),
        url: Yup.string().required("URL is required field"),
        metadata_field: Yup.array(
            Yup.object({
                field: Yup.string().required("Field Name is a required field")
            })
        ),
    });

    const onSaveWoodWingAsset = async (values) => {
        const res = await addWoodWingAsset(clientId, values);
        if (res.status) {
            setIsGetted(!isGetted);
            history.goBack();
        } else {
            getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 3000);
        }
    };

    const handleClickAddField = (values, setFieldValue) => {
        const valueMetaField = [...values?.metadata_field, { field: "" }];
        setFieldValue("metadata_field", valueMetaField);
    };
    
    const handleClickDeleteField = (values, setFieldValue, ind) => {
        const valueMetaField = [...values?.metadata_field].filter(
            (val, id) => id !== ind
        );
        setFieldValue("metadata_field", valueMetaField);
    };

    return (
        <>
            <Formik
                initialValues={initState}
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => onSaveWoodWingAsset(values)}
            >
                {({ dirty, values, setFieldValue }) => (
                    <Form>
                        <ContainerFormGlobal>
                            <IconTitle
                                text="Add WoodWing Assets Server"
                                fontSizeText="32px"
                            />
                            <AlertLeavePage
                                isOpen={dirty}
                                isGetted={isGetted}
                            />
                            <FastField
                                component={InputField}
                                name="name"
                                label="Server Name"
                                placeholder="Server Name"
                            />
                            <FastField
                                component={InputField}
                                name="url"
                                label="URL"
                                placeholder="URL"
                            />
                        </ContainerFormGlobal>
                        <ContainerFormGlobal>
                            <IconTitle
                                text="Add Metadata Field"
                                fontSizeText="32px"
                            />
                            {values.metadata_field?.map((val, ind) => (
                                <Row key={ind}>
                                    <Col sm="6">
                                        <FastField
                                            component={InputField}
                                            name={`metadata_field[${ind}].field`}
                                            label="Field Name"
                                        />
                                    </Col>
                                    <Col
                                        sm="1"
                                        className="d-flex btn align-items-center"
                                    >
                                        <IconTitle
                                            icon="trash-alt"
                                            fontSizeIcon="22px"
                                            onClick={() =>
                                                handleClickDeleteField(
                                                    values,
                                                    setFieldValue,
                                                    ind
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <ButtonCustom
                                text="Add New"
                                fullWidth="auto"
                                outline={true}
                                onClick={() =>
                                    handleClickAddField(values, setFieldValue)
                                }
                            />
                        </ContainerFormGlobal>
                        <ButtonCustom
                            text="Save"
                            fullWidth="auto"
                            outline={true}
                            type="submit"
                            className="mb-4"
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default FormAddWoodWingAsset;
