import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FastField, Field, FieldArray, Form, Formik } from "formik";
import { ContainerForm, ContainerFormFeed } from "./FormAddEditFeedElements";
import { Col, Row } from "reactstrap";
import InputField from "../Common/FormikCustomField/InputField";
import ButtonCustom from "../Common/ButtonCustom";
import IconTitle from "../Common/IconTitle";
import RadioField from "../Common/FormikCustomField/RadioField";
import SelectField from "../Common/FormikCustomField/SelectField";
import { FeedContext } from "../../contexts/FeedProvider";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { InitContext } from "../../contexts/InitProvider";
import ModalAddMetadata from "../Common/ModalAddMetadata";
import ModalShowSearchQuery from "../Common/ModalShowSearchQuery";
import AlertLeavePage from "../Common/AlertLeavePage";
import NavbarBottomHeader from "../../components/Common/NavbarBottomHeader";
import Downloadadble from "./Downloadable";

FormAddEditFeed.propTypes = {
    data: PropTypes.object,
    renditions: PropTypes.array,
    dataSelectFieldWC: PropTypes.array,
    dataWwa: PropTypes.array,
    website: PropTypes.object,
};

FormAddEditFeed.defaultProps = {
    data: {},
    renditions: [],
    dataSelectFieldWC: [],
    dataWwa: [],
    website: {},
};

function FormAddEditFeed(props) {
    const { websiteId } = useParams();
    const { data, renditions, dataSelectFieldWC, dataWwa, website } = props;

    const initState = {
        website_id: websiteId,
        name: "",
        wwa_server_id: "",
        url: "",
        user_name: "",
        password: "",
        server_folder: "",
        server_query: "",
        publish_status: "",
        // "asset_type": "",
        webhook: 0,
        post_type: "product",
        product_type: "simpleProduct",
        data_mapping: {
            mapping: [],
            sync: [
                { wwa: "Woocommerce Product ID", wc: "" },
                { wwa: "Woocommerce Product URL", wc: "" },
            ],
            dakode: [{ wwa: "Dakode Feed ID", wc: "" }],
        },
        downloadadble_products: {
            is_downloadable: false,
            is_virtual: true,
            is_sold_individually: true,
            downloadable_images: {
                name_downloadable_image: "",
                url_downloadable_image: "",
                limit_downloadable_image: "",
                expiry_downloadable_image: ""
            },
            other_downloadable_file: {
                name_downloadable_file: "",
                url_downloadable_file: "Original file",
                limit_downloadable_file: "",
                expiry_downloadable_file: ""
            }
        },
        download_rendition_id: "",
    };

    const feedSchema = Yup.object().shape({
        name: Yup.string().required("Feed name  is a required field").max(50),
        wwa_server_id: Yup.string().required(
            "Server name  is a required field"
        ),
        url: Yup.string().required("Url  is a required field"),
        user_name: Yup.string().required("Username  is a required field"),
        password: Yup.string().required("Password  is a required field"),
        // server_folder: Yup.string().required('Source name is a required field'),
        server_query: Yup.string().required("Source Query is a required field"),
        // asset_type: Yup.string().required('Source asset type is a required field'),
        publish_status: Yup.string().required(
            "Publish Status is a required field"
        ),
        post_type: Yup.string().required("Post type is a required field"),
        product_type: Yup.string().required("Product type is a required field"),
        download_rendition_id: Yup.number().required(
            "Feature image is a required field"
        ),
        downloadadble_products: Yup.object({
            downloadable_images: Yup.object({
                limit_downloadable_image: Yup.number().min(1,'Min is 1 or leave bank').max(999).nullable(true),
                expiry_downloadable_image: Yup.number().min(1,'Min is 1 or leave bank').max(999).nullable(true)
            }),
            other_downloadable_file: Yup.object({
                limit_downloadable_file: Yup.number().min(1,'Min is 1 or leave bank').max(999).nullable(true),
                expiry_downloadable_file: Yup.number().min(1,'Min is 1 or leave bank').max(999).nullable(true)
            })
        }),
        data_mapping: Yup.object({
            mapping: Yup.array(
                Yup.object({
                    wwa: Yup.string().required(
                        "WoodWing Assets is a required field"
                    ),
                    //wc: Yup.string().required('Woocommerce is a required field')
                })
            ),
            sync: Yup.array(
                Yup.object({
                    wwa: Yup.string().required(
                        "WoodWing Assets is a required field"
                    ),
                    wc: Yup.string().required(
                        "Woocommerce is a required field"
                    ),
                })
            ),
        }),
    });

    const history = useHistory();
    const { editFeed, addFeed, importAssets, updateAssets } =
        useContext(FeedContext);
    const { getError } = useContext(InitContext);

    const [infoFeed, setInfoFeed] = useState(initState);
    const [isGetted, setIsGetted] = useState(false);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const serverUrl = dataWwa?.filter(
                (x) => x.id === data.wwa_server_id
            );
            const dataFeed = { ...data, url: serverUrl[0]?.url || "" };
            setInfoFeed(dataFeed);
        }
    }, [data]);
    
    // Action Create && Edit Feed
    const onSubmitFeed = async (values) => {
        const dataForm = { ...values };
        delete dataForm.url;

        const res =
            Object.keys(data).length > 0
                ? await editFeed(dataForm.id, dataForm)
                : await addFeed(dataForm);
        if (res.status) {
            setIsGetted(!isGetted);
            Object.keys(data).length === 0 && history.goBack();
        } else {
            getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 3000);
        }
    };

    const onChangePostTypeField = (setFieldValue, value, metadataMapping) => {
        setFieldValue("post_type", value.target.value);

        value.target.value === "product" || value.target.value === "posts"
            ? setFieldValue("product_type", "simpleProduct")
            : setFieldValue("product_type", "");

        setFieldValue('downloadadble_products.is_downloadable',false );
        setFieldValue('downloadadble_products.is_virtual',true );
        setFieldValue('downloadadble_products.is_sold_individually',true );

        //change metadata mapping when change post type
        if( value.target.value !== "product") {
            
            const dataMappingFilter=(metadataMapping || []).filter(val=> (val.wc !== "description" && val.wc !== "short_description"));
           
            setFieldValue('data_mapping.mapping',dataMappingFilter);
        }
        
    };

    // Filter option for Metadata Mapping Woocomerce
    const getWCOptions = (allOptions, selectedOptions, currentOption, valuesPostType) => {
        const filterByPostType= valuesPostType !== 'product' ? allOptions.filter(val=>(val.value !=="description" && val.value !=="short_description")) : allOptions;
        return filterByPostType.filter(
            (val) =>
                val.value === currentOption ||
                !selectedOptions.includes(val.value)
        );
    };

    const getWWAOptions = (allOptions, selectedOptions, currentOption) => {
        const optionWWa = (dataWwa || []).filter(
            (val) => val.id === parseInt(allOptions)
        );

        const defaultOptionMetadata = optionWWa[0]?.metadata_field.map(
            (val) => ({
                value: val.field,
                name: val.field,
            })
        );
       
        return (defaultOptionMetadata || []).filter(
            (val) =>
                val.value === currentOption ||
                !selectedOptions.includes(val.value)
        );
    };

    const getWWAOptionsDownloadable = (selectedWWAServer) => {
        const optionWWa = (dataWwa || []).filter(
            (val) => val.id === parseInt(selectedWWAServer)
        );

        const defaultOptionMetadata = optionWWa[0]?.metadata_field.map(
            (val) => ({
                value: val.field,
                name: val.field,
            })
        );
       
        return defaultOptionMetadata;
    };

    const filterOptionRenditions = () =>{
        return (renditions || []).filter(val=> parseInt(val.config.validFor) === -1)
    }
    

    const onChangeServerName = (setFieldValue, e, values) => {

        setFieldValue("wwa_server_id", e.target.value, true);
        const urlOfServerName = dataWwa.filter(
            (x) => x.id.toString() === e.target.value
        );
        setFieldValue("url", urlOfServerName[0]?.url || "");

        if (values.data_mapping.mapping) {
            const newDataMapping = values.data_mapping.mapping.filter(
                (val) => val.type_wwa === "custom"
            );
            setFieldValue("data_mapping.mapping", newDataMapping);
        }

        setFieldValue('downloadadble_products.downloadable_images.name_downloadable_image','')
		setFieldValue('downloadadble_products.other_downloadable_file.name_downloadable_file','')
    };

    const validateFieldTestQuery = (value) => {
        let error;
        if (!value) {
            error = "Required field";
        }
        return error;
    };

    const onRunImport = async (messSuccess) => {
        if (Object.keys(data).length > 0) {
            const res = await importAssets(data.id, messSuccess);

            if (!res.status) {
                getError(res?.messError);
                setTimeout(() => {
                    getError(undefined);
                }, 5000);
            }
        }
    };

    const onRunUpdate = async () => {
        if (Object.keys(data).length > 0) {
            const res = await updateAssets(data.id);
            if (!res.status) {
                getError(res?.messError);
                setTimeout(() => {
                    getError(undefined);
                }, 3000);
            }
        }
    };

    return (
        <ContainerFormFeed className="position-relative">
            <Formik
                initialValues={infoFeed}
                enableReinitialize={true}
                validationSchema={feedSchema}
                onSubmit={(values) => onSubmitFeed(values)}
            >
                {({
                    values,
                    setFieldValue,
                    validateField,
                    setFieldTouched,
                    setTouched,
                    dirty,
                }) => {
                    const selectedOptions = values.data_mapping.mapping.map(
                        (val) => val.wc
                    );
                    const selectedOptionsWWA = values.data_mapping.mapping.map(
                        (val) => val.wwa
                    );
                    return (
                        <Form>
                            <AlertLeavePage
                                isOpen={dirty}
                                isGetted={isGetted}
                            />
                            <NavbarBottomHeader
                                titleButton="Save"
                                title={
                                    data?.website
                                        ? `${data?.website?.name} / Feed `
                                        : website
                                        ? `${website?.name} / Feed`
                                        : "Feed"
                                }
                                className="Sticky_input"
                                typeButton="submit"
                            />
                            <ContainerForm>
                                <Row>
                                    <Col
                                        sm="12"
                                        lg="6"
                                        className="feed-name-input"
                                    >
                                        <FastField
                                            component={InputField}
                                            name="name"
                                            label="Feed name"
                                            placeholder="Feed name"
                                        />
                                    </Col>
                                </Row>
                            </ContainerForm>

                            <ContainerForm>
                                {/* WoodWing Assets Form */}
                                <Row>
                                    <Col sm="12" lg="4">
                                        <IconTitle
                                            fontSizeText="30px"
                                            text="WoodWing Assets"
                                        />
                                        <FastField
                                            component={SelectField}
                                            name="wwa_server_id"
                                            label="Server name"
                                            option={dataWwa}
                                            valueType={(item) => item.id}
                                            onHandleChange={(e) =>
                                                onChangeServerName(
                                                    setFieldValue,
                                                    e,
                                                    values
                                                )
                                            }
                                        />
                                        <Field
                                            component={InputField}
                                            name="url"
                                            label="Url"
                                            placeholder="Url"
                                            validate={validateFieldTestQuery}
                                            readOnly={true}
                                        />
                                        <FastField
                                            component={InputField}
                                            name="user_name"
                                            label="Username"
                                            validate={validateFieldTestQuery}
                                            placeholder="Username"
                                        />
                                        <FastField
                                            component={InputField}
                                            type="password"
                                            name="password"
                                            label="Password"
                                            validate={validateFieldTestQuery}
                                            placeholder="Password"
                                        />
                                    </Col>

                                    {/* Source Form */}
                                    <Col sm="12" lg="4">
                                        <IconTitle
                                            fontSizeText="30px"
                                            text="Source"
                                        />
                                        <div style={{ display: "none" }}>
                                            <FastField
                                                component={InputField}
                                                name="server_folder"
                                                type="textarea"
                                                label="Name"
                                                placeholder="Name Source"
                                            />
                                        </div>
                                        <FastField
                                            component={InputField}
                                            name="server_query"
                                            type="textarea"
                                            label="Query"
                                            validate={validateFieldTestQuery}
                                            placeholder="Query"
                                        />
                                        <div className="test">
                                            <ModalShowSearchQuery
                                                value={values}
                                                validateField={validateField}
                                                setTouched={setTouched}
                                            />
                                        </div>
                                        {/* <FastField
											component={RadioField}
											name="asset_type"
											label="Asset type"
											option={[{ value: 'images', name: 'Images' }, { value: 'videos', name: 'Videos' }, { value: 'pdf', name: 'PDF files' }, { value: 'collections', name: 'Collections' }, { value: 'any', name: 'Any' }]}
										/> */}
                                    </Col>

                                    {/* Post Type Form */}
                                    <Col sm="12" lg="4">
                                        <IconTitle
                                            fontSizeText="30px"
                                            text="Post Type"
                                        />
                                        <Field
                                            component={RadioField}
                                            name="post_type"
                                            label="Create new"
                                            onHandleChange={(e) =>
                                                onChangePostTypeField(
                                                    setFieldValue,
                                                    e,
                                                    values.data_mapping.mapping
                                                )
                                            }
                                            option={[
                                                {
                                                    value: "product",
                                                    name: "WooCommerce Product",
                                                },
                                                { value: "posts", name: "Post" },
                                                {
                                                    value: "customPost",
                                                    name: "Custom Post Type",
                                                },
                                            ]}
                                        />
                                        {values.post_type === "customPost" ? (
                                            <FastField
                                                component={InputField}
                                                name="product_type"
                                                label="Product type"
                                            />
                                        ) : values.post_type === "product" ? (
                                            <FastField
                                                component={RadioField}
                                                name="product_type"
                                                label="Product type"
                                                option={[
                                                    {
                                                        value: "simpleProduct",
                                                        name: "Simple product",
                                                    },
                                                    // { value: 'variableProduct', name: 'Variable product' }
                                                ]}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <FastField
                                            component={SelectField}
                                            name="publish_status"
                                            label="Publish Status"
                                            //onHandleChange={(e) => onChangePostTypeField(setFieldValue, e)}
                                            option={[
                                                {
                                                    value: "publish",
                                                    name: "Publish",
                                                },
                                                {
                                                    value: "draft",
                                                    name: "Draft",
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </ContainerForm>

                            {/* Metadata Mapping */}
                            <ContainerForm className="d-none d-lg-block d-xl-block">
                                <FieldArray
                                    name="data_mapping.mapping"
                                    render={(arrayHelpers) => (
                                        <>
                                            <IconTitle
                                                text="Metadata Mapping"
                                                fontSizeText="30px"
                                            />
                                            <Row>
                                                <Col sm="6">
                                                    <IconTitle
                                                        text="WoodWing Assets"
                                                        fontSizeText="16px"
                                                        color="rgb(122, 122, 122)"
                                                    />
                                                </Col>
                                                <Col sm="6">
                                                    <IconTitle
                                                        text="Wordpress"
                                                        fontSizeText="16px"
                                                        color="rgb(122, 122, 122)"
                                                    />
                                                </Col>
                                            </Row>
                                            {values.data_mapping.mapping &&
                                                values.data_mapping.mapping.map(
                                                    (item, idx) => {
                                                        return (
                                                            <div key={idx}>
                                                                <Row>
                                                                    <Col sm="5">
                                                                        {item.type_wwa ===
                                                                        "select" ? (
                                                                            <Field
                                                                                component={
                                                                                    SelectField
                                                                                }
                                                                                name={`data_mapping.mapping[${idx}].wwa`}
                                                                                option={getWWAOptions(
                                                                                    values.wwa_server_id,
                                                                                    selectedOptionsWWA,
                                                                                    values
                                                                                        .data_mapping
                                                                                        .mapping[
                                                                                        idx
                                                                                    ]
                                                                                        .wwa
                                                                                )}
                                                                            />
                                                                        ) : (
                                                                            <FastField
                                                                                component={
                                                                                    InputField
                                                                                }
                                                                                name={`data_mapping.mapping[${idx}].wwa`}
                                                                            />
                                                                        )}
                                                                    </Col>
                                                                    <Col
                                                                        sm="1"
                                                                        className="iconArray mt-2 align-items-baseline"
                                                                    >
                                                                        <IconTitle
                                                                            icon="long-arrow-alt-right"
                                                                            fontSizeIcon="24px"
                                                                        />
                                                                    </Col>
                                                                    {item.type_wc ===
                                                                    "select" ? (
                                                                        <Col sm="5">
                                                                            <Field
                                                                                component={
                                                                                    SelectField
                                                                                }
                                                                                name={`data_mapping.mapping[${idx}].wc`}
                                                                                option={getWCOptions(
                                                                                    dataSelectFieldWC,
                                                                                    selectedOptions,
                                                                                    values
                                                                                        .data_mapping
                                                                                        .mapping[
                                                                                        idx
                                                                                    ]
                                                                                        .wc,
                                                                                    values.post_type
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                    ) : (
                                                                        <Col sm="5">
                                                                            <FastField
                                                                                component={
                                                                                    InputField
                                                                                }
                                                                                name={`data_mapping.mapping[${idx}].wc`}
                                                                            />
                                                                        </Col>
                                                                    )}

                                                                    <Col
                                                                        sm="1"
                                                                        className="d-flex btn align-items-baseline"
                                                                    >
                                                                        <IconTitle
                                                                            icon="trash-alt"
                                                                            fontSizeIcon="22px"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    idx
                                                                                )
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            <ModalAddMetadata
                                                arrayHelpers={arrayHelpers}
                                                wwaServerId={values.wwa_server_id}
                                                allDataWWA={dataWwa}
                                                listSelectWP={dataSelectFieldWC}
                                                postType={values.post_type}
                                            />
                                        </>
                                    )}
                                />

                                <Row>
                                    <Col sm="6">
                                        <IconTitle
                                            text="Dakode"
                                            fontSizeText="16px"
                                            color="rgb(122, 122, 122)"
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <IconTitle
                                            text="Wordpress"
                                            fontSizeText="16px"
                                            color="rgb(122, 122, 122)"
                                        />
                                    </Col>
                                </Row>
                                <>
                                    <FieldArray
                                        name="data_mapping.dakode"
                                        className="fieldArray"
                                        render={() => (
                                            <>
                                                {values.data_mapping.dakode &&
                                                    values.data_mapping.dakode.map(
                                                        (item, idx) => {
                                                            return (
                                                                <div key={idx}>
                                                                    <Row>
                                                                        <Col sm="5">
                                                                            <FastField
                                                                                component={
                                                                                    InputField
                                                                                }
                                                                                name={`data_mapping.dakode[${idx}].wwa`}
                                                                                readOnly={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            sm="1"
                                                                            className="iconArray mt-2 align-items-baseline"
                                                                        >
                                                                            <IconTitle
                                                                                icon="long-arrow-alt-right"
                                                                                fontSizeIcon="24px"
                                                                            />
                                                                        </Col>
                                                                        <Col sm="5">
                                                                            <FastField
                                                                                component={
                                                                                    InputField
                                                                                }
                                                                                name={`data_mapping.dakode[${idx}].wc`}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </>
                                        )}
                                    />
                                </>

                                <Row>
                                    <Col sm="6">
                                        <IconTitle
                                            text="Wordpress"
                                            fontSizeText="16px"
                                            color="rgb(122, 122, 122)"
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <IconTitle
                                            text="WoodWing Assets"
                                            fontSizeText="16px"
                                            color="rgb(122, 122, 122)"
                                        />
                                    </Col>
                                </Row>
                                <>
                                    <FieldArray
                                        name="data_mapping.sync"
                                        className="fieldArray"
                                        render={() => (
                                            <>
                                                {values.data_mapping.sync &&
                                                    values.data_mapping.sync.map(
                                                        (item, idx) => {
                                                            return (
                                                                <div key={idx}>
                                                                    <Row>
                                                                        <Col sm="5">
                                                                            <FastField
                                                                                component={
                                                                                    InputField
                                                                                }
                                                                                name={`data_mapping.sync[${idx}].wwa`}
                                                                                readOnly={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            sm="1"
                                                                            className="iconArray mt-2 align-items-baseline"
                                                                        >
                                                                            <IconTitle
                                                                                icon="long-arrow-alt-right"
                                                                                fontSizeIcon="24px"
                                                                            />
                                                                        </Col>
                                                                        <Col sm="5">
                                                                            <FastField
                                                                                component={
                                                                                    InputField
                                                                                }
                                                                                name={`data_mapping.sync[${idx}].wc`}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </>
                                        )}
                                    />
                                </>
                            </ContainerForm>

                            {/* Images */}
							{values.post_type === "product" && <Downloadadble filterOptionRenditions={filterOptionRenditions()} optionDownloadableImage={getWWAOptionsDownloadable(values.wwa_server_id)}/>}
							
                            <ContainerForm>
                                <Row>
                                    <Col lg="4" sm="12">
                                        <IconTitle
                                            fontSizeText="30px"
                                            text="Images"
                                        />
                                        <FastField
                                            component={SelectField}
                                            name="download_rendition_id"
                                            label="Feature image"
                                            value={
                                                values.download_rendition_id ||
                                                renditions[0]?.id
                                            }
                                            valueType={(item) => item.id}
                                            option={renditions || []}
                                        />
                                    </Col>
                                </Row>
                            </ContainerForm>

                            {/* Scheduler */}
                            {/* <ContainerForm>
								<Row>
									<Col lg="4" sm="12">
										<IconTitle fontSizeText="2rem" text="Scheduler" />
										<ButtonCustom text="Run Once" outline={true} fullWidth="auto" />
										<ButtonCustom text="Save" outline={true} fullWidth="auto" type="submit" />
									</Col>
								</Row>
							</ContainerForm> */}

                            <ContainerForm>
                                <IconTitle
                                    fontSizeText="30px"
                                    text="Bulk Import"
                                />
                                <ButtonCustom
                                    text="Run Once"
                                    outline={true}
                                    fullWidth="auto"
                                    disable={
                                        Object.keys(data).length > 0
                                            ? false
                                            : true
                                    }
                                    onClick={() => onRunImport()}
                                />
                                <FastField
                                    component={RadioField}
                                    name="active_bulk_import"
                                    label="Active"
                                    option={[
                                        { value: "Start", name: "Start" },
                                        { value: "Pause", name: "Pause" },
                                        { value: "stop", name: "Stop" },
                                    ]}
                                    readOnly={true}
                                />
                            </ContainerForm>

                            <ContainerForm>
                                <IconTitle
                                    fontSizeText="30px"
                                    text="Bulk Update"
                                />
                                <ButtonCustom
                                    text="Run Once"
                                    outline={true}
                                    fullWidth="auto"
                                    disable={
                                        Object.keys(data).length > 0
                                            ? false
                                            : true
                                    }
                                    onClick={() => onRunUpdate()}
                                />
                                <FastField
                                    component={RadioField}
                                    name="active_bulk_update"
                                    label="Active"
                                    option={[
                                        { value: "Start", name: "Start" },
                                        { value: "Pause", name: "Pause" },
                                        { value: "stop", name: "Stop" },
                                    ]}
                                    readOnly={true}
                                />
                            </ContainerForm>

                            <ContainerForm>
                                <IconTitle fontSizeText="30px" text="Webhook" />
                                <FastField
                                    component={RadioField}
                                    name="webhook"
                                    label="Active"
                                    option={[
                                        { value: 1, name: "Active" },
                                        { value: 0, name: "Inactive" },
                                    ]}
                                    //readOnly={true}
                                />
                            </ContainerForm>
                        </Form>
                    );
                }}
            </Formik>
        </ContainerFormFeed>
    );
}

export default React.memo(FormAddEditFeed);
