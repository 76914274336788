import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FeedContext } from '../../../contexts/FeedProvider';
import { DownloadRenditionsContext } from '../../../contexts/DownloadRenditionsProvider';
import FormAddEditFeed from '../../../components/FormAddEditFeed';
import { LoginContext } from '../../../contexts/LoginProvider';
import { dataDefaultWc } from '../../../data/Metadata';
import { WoodWingAssetsContext } from '../../../contexts/WoodWingAssetsProvider';
import { ConverDataEncrypt } from '../../../utils/ConverDataEncrypt';



function EditFeed(props) {
	const { id, clientId } = useParams()
	const { feed, getFeedDetails } = useContext(FeedContext)
	const { token, user } = useContext(LoginContext);
	const { renditions, getListRenditions } = useContext(DownloadRenditionsContext)
	const { woodWingAssets, getListWoodWingAssets } = useContext(WoodWingAssetsContext)
	const [isLoading, setIsLoading] = useState(false);

	const getData = async () => {
		setIsLoading(false)
		await getListRenditions(clientId);
		await getFeedDetails(id);
		await getListWoodWingAssets(clientId);
	}

	useEffect(() => {
		if (id && token && user) {
			let mounted = true;
			getData().then(() => mounted && setIsLoading(true));
			return () => mounted = false;
		}
	}, [id, user, token])
	
	return (
		<div>
			{isLoading && (
				<>
					<FormAddEditFeed data={feed} renditions={renditions} clientId={clientId} dataSelectFieldWC={dataDefaultWc} dataWwa={woodWingAssets} />
				</>
			)
			}
		</div>
	);
}

export default EditFeed;