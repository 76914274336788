import { FormFeedback } from 'reactstrap';
import styled from 'styled-components';



export const ContainerErrorMes = styled(FormFeedback)`
	width: auto;
    max-width: 100%;
    background: #C74A4A;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    box-sizing: border-box;
    position: relative;
    padding: 12px;
    font-size: 14px;
    line-height: 20px !important;
    margin: 12px 0 0 0;
`

export const IconArrow = styled.div`
	top: -17px;
    left: 10px;
    position: absolute;
    z-index: 1;
    color: #C74A4A;
    font-size: 28px;
    line-height: 1em !important;
`

