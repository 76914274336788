import React, { createContext, useState } from 'react';

export const InitContext = createContext();
function InitProvider({ children }) {
	const [error, setError] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const getError = (messError) => {
		setError(messError);
	}

	const addLoading = () => {
		setLoading(true)
	}
	const removeLoading = () => {
		 setLoading(false)
	}

	return (
		<InitContext.Provider value={{ error, loading, getError, addLoading, removeLoading }}>
			{children}
		</InitContext.Provider>
	);
}

export default InitProvider;