import styled from 'styled-components';



export const ContainerListFeeds = styled.div`
	display:grid;
	grid-template-columns: repeat(2,1fr);
	gap: 15px;
	border-radius: 4px;
	padding:40px 0 20px;
	

	@media(max-width: 992px){
		grid-template-columns: repeat(1,1fr);
	}
`