import { Collapse, Row, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";



export const WrapperFormAccount = styled(Row)`
	& ul {
		border: none;
		flex-direction: column;
		& >li{
			border: 1px solid #ddd;
			max-width: 55px;
			border-radius: 4px;
		&:first-child{
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			}
		&:last-child{
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			} 
			& > a.active{
				background-color: rgb(59, 161, 218) !important;
				border: none;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				& svg{
					color: white;
				}
			}
			
			& a.nav-link div.button_custom{
					margin-bottom: 0;
					justify-content: center;
					& svg{
						margin-right: 0;
					}
			}
		}

	}

	@media(max-width: 992px){
		& ul{
			display: none;
		}
	}
	@media(max-width: 500px){
		padding: 50px 50px 30px 50px;
	}
`


export const CollapseAccount = styled(Collapse)`

  & form input{
	  margin-bottom: 15px;
  }

@media(min-width: 992px){
		  :not(.show){
		display: block;
	}
}
	
`

export const TabPaneAccount = styled(TabPane)`
	margin-bottom: 10px;
 	& h4{
		 border-bottom: 1px solid rgb(238, 238, 238);
		 & label{
			 font-weight: bold
		 }
		}
	@media(min-width: 992px){
		& h4{
			pointer-events: none;
			border-bottom: none;			
		}
	}


`

export const TabContentAccount = styled(TabContent)`
@media(max-width: 992px){
	& > .tab-pane{
		display:block
	}
}
	
`