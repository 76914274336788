import React, { useContext, useEffect, useState } from "react";
import { Form, Formik, FastField } from "formik";
import ButtonCustom from "../../Common/ButtonCustom";
import PropTypes from "prop-types";
import { ContainerWwAssets } from "./FormWoodwingAssetsElements";
import InputField from "../../Common/FormikCustomField/InputField";
import * as Yup from "yup";
import { WoodWingAssetsContext } from "../../../contexts/WoodWingAssetsProvider";
import { InitContext } from "../../../contexts/InitProvider";
import AlertLeavePage from "../../Common/AlertLeavePage";
import IconTitle from "../../Common/IconTitle";
import { Col, Row } from "reactstrap";

FormWoodwingAsset.propTypes = {
    data: PropTypes.object,
    clientId: PropTypes.string,
};

FormWoodwingAsset.defaultProps = {
    data: {},
    clientId: "",
};

function FormWoodwingAsset({ data, clientId }) {
    const { editWoodWingAsset, deleteWoodWingAsset } = useContext(
        WoodWingAssetsContext
    );
    const { getError } = useContext(InitContext);
    const [isGetted, setIsGetted] = useState(false);
    const [isEdit,setIsEdit]= useState(false);

    const initState = {
        name: "",
        url: "",
        metadata_field: [],
    };

    const schema = Yup.object().shape({
        name: Yup.string().required("Server Name is required field"),
        url: Yup.string().required("URL is required field"),
        metadata_field: Yup.array(
            Yup.object({
                field: Yup.string().required("Field Name is a required field"),
            })
        ),
    });

    const [woodWingAsset, setWoodWingAsset] = useState(initState);

    useEffect(() => {
        if (data) {
            setWoodWingAsset(data);
        }
    }, [data]);

    const onEditWwaServer = async (values) => {
        const res = await editWoodWingAsset(data.id, values);

        if (!res.status) {
            getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 3000);
        } else {
            setIsGetted(!isGetted);
        }
        setIsEdit(false);
    };

    const onDeleteWwaServer = async () => {
        const res = await deleteWoodWingAsset(data.id);
        if (!res.status) {
            getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 3000);
        }
    };

    const handleClickAddField = (values, setFieldValue) => {
        const valueMetaField = [...values?.metadata_field, { field: "" }];
        setFieldValue("metadata_field", valueMetaField);
    };

    const handleClickDeleteField = (
        values,
        setFieldValue,
        ind
    ) => {
        if (isEdit) {
            const valueMetaField = [...values?.metadata_field].filter(
                (val, id) => id !== ind
            );
            setFieldValue("metadata_field", valueMetaField);
        }
    };


    const handleEdit= (setSubmitting,isSubmitting) => {
        setSubmitting(!isSubmitting);
        setIsEdit(!isEdit);
    }

    return (
        <>
            <Formik
                initialValues={woodWingAsset}
                enableReinitialize={true}
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, setSubmitting) => onEditWwaServer(values)}
            >
                {({
                    isSubmitting,
                    setSubmitting,
                    dirty,
                    values,
                    setFieldValue,
                    isValid,
                }) => {
                    return (
                        <Form>
                            <AlertLeavePage
                                isOpen={dirty}
                                isGetted={isGetted}
                            />
                            <ContainerWwAssets>
                                <div className="d-flex flex-md-row flex-column justify-content-sm-between align-items-center mb-3">
                                    <IconTitle text="WoodWing Assets" fontSizeText="30px"/>
                                    <ButtonCustom
                                        text="Edit"
                                        outline={true}
                                        colorDefault="primary"
                                        onClick={() =>handleEdit(setSubmitting,isSubmitting)
                                            // setSubmitting(!isSubmitting)
                                        }
                                        fullWidth="auto"
                                    />
                                </div>
                                <Row>
                                    <Col sm="12" md="6">
                                        <FastField
                                            component={InputField}
                                            name="name"
                                            label="Server Name"
                                            readOnly={!isEdit}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="6">
                                        <FastField
                                            component={InputField}
                                            name="url"
                                            label="URL"
                                            readOnly={!isEdit}
                                        />
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-end">
                                    <ButtonCustom
                                        text="Delete"
                                        colorDefault="primary"
                                        fullWidth="auto"
                                        outline={true}
                                        onClick={onDeleteWwaServer}
                                    />
                                </div>
                            </ContainerWwAssets>

                            <ContainerWwAssets>
                                <IconTitle
                                    text="Metadata Fields"
                                    fontSizeText="32px"
                                />
                                {values.metadata_field &&
                                    values.metadata_field?.map((val, ind) => (
                                        <Row key={ind}>
                                            <Col sm="6">
                                                <FastField
                                                    component={InputField}
                                                    name={`metadata_field[${ind}].field`}
                                                    label="Field Name"
                                                    readOnly={!isEdit}
                                                />
                                            </Col>

                                            <Col
                                                sm="1"
                                                className="delete-icon btn"
                                            >
                                                <IconTitle
                                                    className={isEdit ? 'visible' : 'invisible '}
                                                    icon="trash-alt"
                                                    fontSizeIcon="22px"
                                                    position="center"
                                                    onClickEvent={() =>
                                                        handleClickDeleteField(
                                                            values,
                                                            setFieldValue,
                                                            ind
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                {isEdit && (
                                    <ButtonCustom
                                        text="Add New"
                                        fullWidth="auto"
                                        outline={true}
                                        // disable={!isSubmitting && isValid}
                                        onClick={() =>
                                            handleClickAddField(
                                                values,
                                                setFieldValue
                                            )
                                        }
                                    />
                                )}
                            </ContainerWwAssets>
                            <ButtonCustom
                                className={isEdit ? 'd-block' : 'd-none'}
                                text="Save"
                                colorDefault="primary"
                                fullWidth="auto"
                                outline={true}
                                type="submit"
                            />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default FormWoodwingAsset;
