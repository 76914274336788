import styled from "styled-components";


export const ContainerFormEdit= styled.div`
	& form input, form select{
		margin-bottom: 15px
	}
	@media(max-width: 768px){
		padding: 50px 50px 30px 50px;
	}
`