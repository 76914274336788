import React, { useContext, useEffect } from 'react';
import FormWoodwingAsset from '../../../components/WoodwingAssets/FormWoodwingAsset';
import ListFormWebsites from '../../../components/Websites/ListFormWebsites';
import { Col, Row } from 'reactstrap';
import NavbarBottomHeader from '../../../components/Common/NavbarBottomHeader';
import { useHistory, useParams } from 'react-router-dom';
import { LoginContext } from '../../../contexts/LoginProvider';
import { InitContext } from '../../../contexts/InitProvider';
import { WoodWingAssetsContext } from '../../../contexts/WoodWingAssetsProvider';
import { ClientsContext } from '../../../contexts/ClientsProvider';

function HomeWoodWingAssets(props) {
  const { clientId } = useParams();
  const history = useHistory();
  const { user } = useContext(LoginContext);
  const { getError } = useContext(InitContext);
  const { woodWingAssets, getListWoodWingAssets } = useContext(WoodWingAssetsContext);

  // const getData = async (clientId) => {
  //   if ((user.role === "Admin" && clientId) || (user.role === "Client" && !clientId )) {
  //     const resWwwa = await getListWoodWingAssets(clientId)
  //     user.role === "Admin" && await getClientDetails(clientId) 
  //     if (!resWwwa?.status) {
  //       getError(resWwwa?.messError);
  //       setTimeout(() => {
  //         getError(undefined)
  //       }, 3000);
  //     }
  //   }
  //   else {
  //     user.role === "Admin" ? history.push("/client") : history.push("/")
  //   }
  // }

  const getData = async (clientId) => {		
    const res = await getListWoodWingAssets(clientId)
    if (!res.status) {
      getError(res?.messError);
      setTimeout(() => {
        getError(undefined)
      }, 3000);
    }
}

    useEffect(() => {
      if (user) {
        (!clientId && user?.role === "Admin") ? history.push("/client") : getData(clientId);
      }
    }, [user])


  return (
    <Row>
      <Col sm='12'>
        <NavbarBottomHeader className="sticky_header"  clientSelected={(woodWingAssets) ? woodWingAssets[0]?.client?.name : ''}  title="WoodWing Assets" titleButton="Add New Assets Server" linkTo={clientId ? `/client/${clientId}/add-assets-server` : "/add-assets-server"} />
        <ListFormWebsites
          data={woodWingAssets}
          childComponent={(item) => <FormWoodwingAsset data={item} />}
        />
      </Col>
    </Row>
  );
}

export default HomeWoodWingAssets;
