import React, { useContext } from 'react';
import { Row } from 'reactstrap';
import { ContainerLogin, FormLogin, ImagesLogin } from './LoginLayoutElements';
import LogoLogin from '../../assets/images/ilya-yakover-254069.jpg';
import ErrorMessageGlobal from '../../components/Common/ErrorMessageGlobal';
import { InitContext } from '../../contexts/InitProvider';
import LoadingTemplate from '../../components/Common/LoadingTemplate';

LoginLayout.propTypes = {

};

function LoginLayout({ children }) {
	const { error, loading } = useContext(InitContext);

	return (
		<>
			{loading && <LoadingTemplate />}
			<ContainerLogin fluid>
				<Row>
					<FormLogin sm="12" md="5" lg="4">
						{error && <ErrorMessageGlobal messError={error} />}
						{children}
					</FormLogin>
					<ImagesLogin sm="12" md="7" lg="8" bg={LogoLogin} />
				</Row>
			</ContainerLogin>
		</>
	);
}
export default LoginLayout;