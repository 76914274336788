
import styled from 'styled-components';




export const TitleFeed = styled.h2`
	color: rgb(0, 158, 227);
	font-size: 30px;
	font-weight:400;
	margin-bottom: 0;
`

export const TitleWrapper = styled.div`
	justify-content: space-between;
	align-items:center;
	display: flex;

	& .block-title-header .animate svg:hover , .block-button-header .animate svg:hover {
		color:#009ee3;
	}

	& h2 {
		margin-right: 15px;
		margin-bottom: 0;
	}
	
	@media(max-width: 500px){
		flex-wrap: wrap;		
		& h2{
			width: 100%;
			padding-bottom: 10px;
		}
		& > div{
			display: flex;
			width: 100%;
			justify-content: flex-end;
		}
	}
	& > h2 {
		text-transform: capitalize; 
		font-size: 30px;
	}
	& > input {
		font-size: 30px;
	}
`

export const ContainerFeed = styled.div`

	background:${props => (props.activeHook === "Active"  ? "#e8ffd2" : '#fff')};
	border: 1px solid #7a7a7a;
	padding: 10px;
	border-radius: 4px;
 & form {
	 display: flex;
	 flex-direction: column;
	 gap: 0px;
	 & .button_custom{
		margin-bottom:0;
	 }
 }

`