import React, { createContext, useState } from 'react';
import useApiCRUD from '../hooks/useApiCRUD';
import { ConverDataEncrypt } from '../utils/ConverDataEncrypt';

export const ClientsContext = createContext();

function ClientsProvider({ children }) {
	const [clients, setClients] = useState([]);
	const [clientDetails, setClientDetails] = useState();
	const [apiCreate, apiEdit, apiGetList, apiGetOne] = useApiCRUD()


	const addClient = async (client) => {
		const url = '/api/clients';
		const dataClient= {...client, password: ConverDataEncrypt.encrypt(client?.password), confirmPassword: ConverDataEncrypt.encrypt(client?.confirmPassword)  }
		const res = await apiCreate(url, dataClient);
		return res;

	}

	const getListClients = async () => {
		const url = '/api/clients';
		const res = await apiGetList(url);
		res.status && setClients(res?.data);
		return res;
	}

	const editClient = async (id, clientInfo) => {
		const url = `/api/clients/${id}`;
		const res = await apiEdit(url, clientInfo);
		return res;
	}

	const getClientDetails = async (id) => {
		const url = `/api/clients/${id}`;
		const res = await apiGetOne(url);
		res.status && setClientDetails(res.data)
		return res;
	}


	return (
		<ClientsContext.Provider value={{ clientDetails, clients, addClient, getListClients, editClient, getClientDetails }}>
			{children}
		</ClientsContext.Provider>
	);
}

export default ClientsProvider;
