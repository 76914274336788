
import { Popover } from 'antd';
import React, { useContext, useState } from 'react';
import { FeedContext } from '../../../contexts/FeedProvider';
// import { InitContext } from '../../../contexts/InitProvider';
import ButtonCustom from '../ButtonCustom';
import { CodeWrapper, ContainerModalQuery, ModalQuery } from './ModalShowSearchQueryElement';

function ModalShowSearchQuery(props) {
  const { value, validateField, setTouched } = props;
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const { getError } = useContext(InitContext)
  const [result, setResult] = useState(null);
  const { testQuery } = useContext(FeedContext)

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(result);
  }

  const onClickTestQuery = async () => {
    const validated = await Promise.all([
      validateField('user_name'),
      validateField('url'),
      validateField('password'),
      validateField('server_query'),
    ])
    setTouched({ 'user_name': true, 'password': true, 'url': true, 'server_query': true })
    const checValaidate = validated.filter(x => x !== undefined).length > 0;

    if (!checValaidate) {
      //Post API Test query
      setIsLoading(true)
      const dataQuery = {
        url: value?.url,
        username: value?.user_name,
        password: value?.password,
        query: value?.server_query
      }
      const res = await testQuery(dataQuery);
      setIsLoading(false);
      if (res.status) {
        const resultQuery = JSON.stringify(res?.data, null, 2)
        setResult(resultQuery)
      } else {
        setResult("Invalid username or password")
        // getError('Test query fail')
        // setTimeout(() => {
        //   getError(undefined)
        // }, 3000);
      }
      setVisible(true)
    }
  }

  const content = () => <h6>Copied Succesfully</h6>

  const titleSearchQuery = () =>{
    return (
      <>
      <span>Test SearchQuery</span> 
      {result !== "Invalid username or password" && <span> [Login Successful]</span>}
      </>
    )
  }


  return (
    <ContainerModalQuery>
      <ButtonCustom text="Test" onClick={onClickTestQuery} outline={true} fullWidth="auto" isLoading={isLoading} />
      <ModalQuery
        title={titleSearchQuery()}
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        width={600}
        footer={null}
      >
        <CodeWrapper>
          {`${result}`}
        </CodeWrapper>
        <div className="d-flex justify-content-end bg-dark p-2 rounded-bottom">
          <Popover content={content} trigger="click">
            <ButtonCustom text="Copy To Clipboard" fullWidth="auto" outline={true} className="bg-dark border-white text-light" onClick={copyToClipboard} />
          </Popover>
        </div>
      </ModalQuery>
    </ContainerModalQuery>
  );
}

export default ModalShowSearchQuery;