import React, { useState } from 'react';
import classnames from 'classnames';
import IconTitle from '../../Common/IconTitle';
import { Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import { CollapseAccount, TabContentAccount, TabPaneAccount, WrapperFormAccount } from './ListFormAccountElements';
import ChangePasswordAccount from '../ChangePasswordAccount';
import UsernameAccount from '../UsernameAccount';
import { Link } from 'react-router-dom';

ListFormAccount.propTypes = {

};

function ListFormAccount(props) {
	const [activeTab, setActiveTab] = useState(1);
	const [isOpen, setIsOpen] = useState(activeTab);

	const toggle = (idx) => {
		setActiveTab(idx);
	}

	const toogleCollapse = (idx) => {
		setIsOpen(idx)
	}

	const accountIcon = () => {
		return (
			<div className="d-flex justify-content-between">
				<IconTitle text="Account" icon="user" fontSizeIcon="24px" fontSizeText="18px" colorIcon="rgb(85, 85, 85)" color="rgb(85, 85, 85)" />
				<Link to="/profile/edit-profile" style={{pointerEvents: 'auto'}}><IconTitle icon="cog" colorIcon="rgb(85, 85, 85)" fontSizeIcon="20px" /></Link>
			</div>
		)
	}

	const ChangePasswordIcon = () => {
		return <IconTitle text="ChangePassword" icon="asterisk" fontSizeIcon="24px" fontSizeText="18px" colorIcon="rgb(85, 85, 85)" color="rgb(85, 85, 85)" />
	}

	return (
		<>
			<div className="d-flex justify-content-center d-lg-none flex-column mt-5">
				<IconTitle text="Client Admin" icon="user-circle" fontSizeIcon="120px" flexDirection="column" color="black" fontSizeText="24px" />
				<Link to="#" className="text-center">View profile</Link>
			</div>
			<WrapperFormAccount>
				<Col lg="2" sm="12">
					<Nav tabs >
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === 1 })}
								onClick={() => { toggle(1) }}
							>
								<IconTitle icon="user" fontSizeIcon="22px" />
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === 2 })}
								onClick={() => { toggle(2) }}
							>
								<IconTitle icon="asterisk" fontSizeIcon="22px" />
							</NavLink>
						</NavItem>
					</Nav>
				</Col>
				<Col lg="10" sm="12" >
					<TabContentAccount activeTab={activeTab} >
						<TabPaneAccount tabId={1}>
							<Row>
								<Col sm="12">
									<h4 onClick={() => toogleCollapse(1)}>{accountIcon()}</h4>
									<CollapseAccount isOpen={isOpen === 1}>
										<UsernameAccount />
									</CollapseAccount>
								</Col>
							</Row>
						</TabPaneAccount>
						<TabPaneAccount tabId={2}>
							<Row>
								<Col sm="12">
									<h4 onClick={() => toogleCollapse(2)}>{ChangePasswordIcon()}</h4>
									<CollapseAccount isOpen={2 === isOpen}>
										<ChangePasswordAccount />
									</CollapseAccount>
								</Col>
							</Row>
						</TabPaneAccount>
					</TabContentAccount>
				</Col>
			</WrapperFormAccount>
		</>
	);
}

export default ListFormAccount;