import React from 'react';
import FormAddDownloadRenditions from '../../../components/DownloadRenditions/FormAddDownloadRenditions';


function AddDownloadRenditions(props) {
	return (
		<div className="mt-4">
				<FormAddDownloadRenditions />
		</div>
	);
}

export default AddDownloadRenditions;