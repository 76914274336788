import React, { useContext } from 'react';
import { ContainerNavbar, MenuNavbarHeader, WrapperNavbar } from './NavbarElements'
import { Container } from 'reactstrap';
import IconTitle from '../../Common/IconTitle';
import { Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavItem } from 'reactstrap';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { LoginContext } from '../../../contexts/LoginProvider';


function MainNavbar(props) {
	const { clientId } = useParams();
	const history = useHistory();
	const { user, signOut, token } = useContext(LoginContext)
	const url = useLocation();


	const onSignOut = () => {
		history.push("/login");
		signOut();
	}

	return token ? (
		<ContainerNavbar fluid>
			<Container fluid>
				<WrapperNavbar>
					<div className="icons-navbar">
						{(url.pathname !== '/' && `/client/${clientId}/website` !== url.pathname) ?
							<Link to={clientId ? `/client/${clientId}/website` : '/'}><IconTitle text="Websites" icon="arrow-alt-circle-left" color="white" colorIcon="white" fontSizeIcon="30px" /></Link> : <p>Welcome, {user && user.fullname}</p>
						}
					</div>
					<MenuNavbarHeader>
						<Nav className="ml-auto" navbar>
							<NavItem>
								<Link className="nav-link" to={clientId ? `/client/${clientId}/woodwing-assets` : '/woodwing-assets'} >WoodWing Assets</Link>
							</NavItem>
							<NavItem>
								<Link className="nav-link" to={clientId ? `/client/${clientId}/download-renditions` : '/download-renditions'} >Download Renditions</Link>
							</NavItem>
							<UncontrolledDropdown setActiveFromChild>
								<DropdownToggle tag="a" className="nav-link">
									<IconTitle icon="user-circle" colorIcon="white" fontSizeIcon="2rem" />
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem tag="span"><Link to={clientId ? '/client' : "/"}>{clientId ? 'Client' : 'Websites'} </Link></DropdownItem>
									<DropdownItem tag="span" ><Link to={clientId ? `/client/${clientId}/woodwing-assets` : '/woodwing-assets'}>WoodWing Assets </Link></DropdownItem>
									<DropdownItem tag="span" ><Link to={clientId ? `/client/${clientId}/download-renditions` : '/download-renditions'}> Download Renditions </Link></DropdownItem>
									<DropdownItem tag="span"><Link to="/account/general">My Account </Link> </DropdownItem>
									<DropdownItem tag="span" onClick={onSignOut} >Sign out</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</MenuNavbarHeader>
				</WrapperNavbar>
			</Container>
		</ContainerNavbar>
	) : <Redirect to='/login' />;
}
export default React.memo(MainNavbar);