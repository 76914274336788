import styled from 'styled-components';




export const ContainerFormFeed = styled.div`
	& .Sticky_input{	
    position: sticky;
    z-index: 99;
	top: 75px;
	transition: 0.6s;
	& button{
		width: auto;
	}
}
& .feed-name-input > div {
	margin-bottom: 0;
}
`

export const ContainerForm = styled.div`

	border: 1px solid #7a7a7a;
	border-radius:3px;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 20px 30px;
	& button {
		margin-top: 15px;
		margin-bottom: 5px;
	}
	& button.button-add-new{
		margin-top: 0 ;
		margin-bottom: 20px ;
	}
	& .iconArray{
		display:flex;
		justify-content:center;
		align-items:center;
	}
`