import styled from 'styled-components';

export const ContainerFormClientsHome = styled.div`
  border: 1px solid rgb(122, 122, 122);
  padding: 25px;
  border-radius: 4px;
  & form > .row {
    margin-top: 20px;
  }
  & label {
    font-weight: 400;
    font-size: 20px;
  }
  & .col {
    margin-bottom: 0;
  }
  & h2 {
    color: #009ee3;
    font-size: 30px;
    font-weight: 400;
  }
`;
