import React from 'react';
import PropTypes from 'prop-types';
import { Label, Row } from 'reactstrap';
import { ColIconTitle, FontAwesomeTitle } from './IconTitleElements';




IconTitle.propTypes = {
	fontSizeText: PropTypes.string,
	icon: PropTypes.string,
	text: PropTypes.string,
	color: PropTypes.string,
	colorIcon: PropTypes.string,
	fontSizeIcon: PropTypes.string,
	onClick: PropTypes.func,
	position: PropTypes.string,
	flexDirection: PropTypes.string,
	onClickEvent: PropTypes.func,
	className: PropTypes.string
};

IconTitle.defaultProps = {
	onClick: null,
	fontSizeText: '16px',
	icon: '',
	text: '',
	color: '#009EE3',
	colorIcon: 'rgb(0, 158, 227)',
	fontSizeIcon: '14px',
	position: '',
	flexDirection: '',
	onClickEvent: null,
	className: ''
}

function IconTitle(props) {
	const { icon, text, color, colorIcon, fontSizeIcon, onClick, position, fontSizeText, flexDirection, onClickEvent, className } = props;


	return (
		<Row>
			<ColIconTitle className={`button_custom ${className}`} onClick={onClick} position={position} colortext={color} sizetext={fontSizeText} flex_direction={flexDirection}>
				{icon && <div className="animate"><FontAwesomeTitle icon={icon} coloricon={colorIcon} fontsizeicon={fontSizeIcon} onClick={onClickEvent} /></div>}
				{text && <Label>{text}</Label>}
			</ColIconTitle>
		</Row>
	);
}

export default IconTitle;