import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import NavbarBottomHeader from "../../../components/Common/NavbarBottomHeader";
import { ClientsContext } from "../../../contexts/ClientsProvider";
import { LoginContext } from "../../../contexts/LoginProvider";
import { InitContext } from "../../../contexts/InitProvider";
import ListFormWebsites from "../../../components/Websites/ListFormWebsites";
import FormClientsHome from "../../../components/Clients/FormClientsHome";
import ModalCustom from "../../../components/Common/ModalCustom";
import { message } from "antd";

function ClientsHome(props) {
    const { getListClients, clients } = useContext(ClientsContext);
    const { getError } = useContext(InitContext);
    const { user, resetPassword } = useContext(LoginContext);
    const [modal, setModal] = useState(false);
    const [emailResetPass, setEmailResetPass] = useState("");

    const onGetListClients = async () => {
        const res = await getListClients();
        if (!res.status) {
            await getError(res?.messError);
            setTimeout(() => {
                getError(undefined);
            }, 3000);
        }
    };

    useEffect(() => {
        if (user) {
            onGetListClients();
        }
    }, [user]);

    const toggle = () => setModal(false);

    const onHandleSetPassword = (email) => {
        if (email) {
            setModal(true);
            setEmailResetPass(email);
        }
    };

    const onHandleResetPassClient = async () => {
        if (emailResetPass) {
            const emailClient = { email: emailResetPass };
            const res = await resetPassword(emailClient);
            setModal(false);
            if (res.status) {
                await message.success({
                    content: "Send mail to set password success",
                    className: "custom-class",
                    style: {
                        marginTop: "15vh",
                    },
                    duration: 4,
                });
            } else {
                getError(res?.messError);
                setTimeout(() => {
                    getError(undefined);
                }, 5000);
            }
        }
    };

    return (
        <Row>
            <Col sm="12">
                <NavbarBottomHeader
                    className="sticky_header"
                    title="Clients"
                    titleButton="Add New Client"
                    linkTo="/add-client"
                />
                <ModalCustom
                    stateModal={modal}
                    toggle={toggle}
                    content="Would you like to send an email to reset password?"
                    textButton="Save"
                    header="Send email to set password"
                    onClick={onHandleResetPassClient}
                />
                <ListFormWebsites
                    isPageWebsite={true}
                    data={clients}
                    childComponent={(item) => (
                        <FormClientsHome
                            data={item}
                            onSetPassword={onHandleSetPassword}
                        />
                    )}
                />
            </Col>
        </Row>
    );
}

export default React.memo(ClientsHome);
