import React from 'react';
import { ContainerLoading } from './LoadingTemplateElements';
import { Spin } from 'antd';
import {
	SyncOutlined,
  } from '@ant-design/icons';

function LoadingTemplate(props) {
	const antIcon = <SyncOutlined style={{ fontSize: 80 }} spin />;
	return (
		<ContainerLoading>			
			<Spin tip="Loading....." size="large" indicator={antIcon} />						
		</ContainerLoading>
	);
}
export default LoadingTemplate;