import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'reactstrap';
import styled from 'styled-components';



export const ColIconTitle = styled(Col)`
	text-align: left;
	display: flex;
	align-items: center;
	margin-bottom: 5px;	
	${props => props.position && `justify-content: ${props.position}`};
	${props => props.flex_direction && `flex-direction: ${props.flex_direction}`};
	& label {
		font-size: ${props => props.sizetext};
		margin-bottom: 0;
		color: ${props => props.colortext};
		font-weight: 400;
	} 
		
`


export const FontAwesomeTitle = styled(FontAwesomeIcon)`
margin-right: 10px;
color:  ${props => props.coloricon};
font-size: ${props => props.fontsizeicon};
`