export const dataDefaultWc = [
	{
		value: 'name',
		name: 'Name'
	},
	{
		value: 'description',
		name: 'Description'
	},
	{
		value: 'short_description',
		name: 'Short Description'
	},
	// {
	// 	value: 'categories',
	// 	name: 'Categories'
	// },
	// {
	// 	value: 'tags',
	// 	name: 'Tags'
	// },
	// {
	// 	value: 'image',
	// 	name: 'Image'
	// },
]