import styled from 'styled-components';



export const ContainerRadio = styled.div`

 & .ant-radio-disabled + span{
	 color: rgb(84, 89, 95);
 }

 & .ant-radio-group{
	 margin-bottom: 20px;
 }

 & .ant-radio-inner {
	 border-color: grey;
	 background: white;
 }
`