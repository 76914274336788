import { Row } from 'reactstrap';
import styled from 'styled-components';



export const GroupButton = styled(Row)`	
	gap: 20px;

	& div:last-child button{
		box-shadow:rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px
	}

	@media(max-width:768px){
		justify-content: space-between;
		gap: 0;
		flex-wrap: nowrap;
	}

	@media(max-width:460px){
		flex-wrap: wrap;
		gap:1.5rem;
	}
`

export const ContainerLoginForm = styled(Row)`
	width:100%;

	@media(max-width: 768px){
		padding: 40px;
	}

	@media(max-width: 560px){
		padding: 20px
	}
`