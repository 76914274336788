import React from 'react';
import FormAddWoodWingAsset from '../../../components/WoodwingAssets/FormAddWoodWingAsset';
import { useParams } from 'react-router-dom';

function AddWoodWingAssets(props) {
	const {clientId}=useParams();
	return (
		<div>
			<FormAddWoodWingAsset clientId={clientId} />
		</div>
	);
}

export default AddWoodWingAssets;