import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FastField, Form, Formik } from 'formik';
import InputField from '../../Common/FormikCustomField/InputField';
import IconTitle from '../../Common/IconTitle';
import ButtonCustom from '../../Common/ButtonCustom';
import { ContainerFormGlobal } from '../../../globalStyles';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { ClientsContext } from '../../../contexts/ClientsProvider';
import { Col, Row } from 'reactstrap';
import AlertLeavePage from '../../Common/AlertLeavePage';
import { InitContext } from '../../../contexts/InitProvider';

FormEditClient.propTypes = {
	data: PropTypes.object,
};

FormEditClient.defaultProps = {
	data: {},
}

function FormEditClient(props) {
	const initState = {
		name: "",
		address: "",
		admin_name: "",
		email: "",
		start_date: "",
		end_date: "",
	}

	const { data } = props;
	const history = useHistory();
	const { editClient } = useContext(ClientsContext)
	const [dataClient, setDataClient] = useState(initState);
	const [isGetted, setIsGetted] = useState(false);
	const { getError } = useContext(InitContext);

	useEffect(() => {
		if (data && Object.keys(data).length !== 0) {
			const client = {
				name: data.name,
				address: data.address,
				admin_name: data.admin.admin_name,
				email: data.admin.email,
				start_date: data.license[0].start_date,
				end_date: (data.license[0].end_date) ? data.license[0].end_date : '',
			}
			setDataClient(client)
		}
	}, [data])

	//Validate form Add Client
	const clientSchema = Yup.object().shape({
		name: Yup.string().required('Company Name is required field'),
		admin_name: Yup.string().required("Admin Name is required field"),
		email: Yup.string().email('Email').required("Email is required field"),
		start_date: Yup.date().required("Start date is required field"),
		end_date: Yup.date().min(
			Yup.ref('start_date'),
			({ min }) => `Date needs to be before !!`),
	})

	//Action edit client
	const onEditClient = async (id, values) => {
		const res = await editClient(id, values);
		if (res.status) {
			setIsGetted(true);
			history.goBack();
		} else {
			getError(res?.messError);
			setTimeout(() => {
				getError(undefined)
			}, 3000);
		}
	}

	return (
		<ContainerFormGlobal>
			<IconTitle text='Edit Client'
				fontSizeText="2rem" />
			<Formik
				initialValues={dataClient}
				validationSchema={clientSchema}
				validateOnBlur={false}
				validateOnChange={false}
				enableReinitialize={true}
				onSubmit={(values) => onEditClient(data.id, values)}
			>
				{({ isValid, isSubmitting, dirty }) => {
					return (
						<Form>
							<AlertLeavePage isOpen={dirty} isGetted={isGetted} />
							<IconTitle text="Company Info"
								icon="address-card" />
							<FastField
								component={InputField}
								name="name"
								label="Name"
								placeholder="Company Name"
								colorLabel='#7A7A7A'
							/>
							<FastField
								component={InputField}
								name="address"
								label="Address"
								placeholder="Address"
								colorLabel='#7A7A7A'
							/>
							<IconTitle text="Admin" icon="user" />
							<FastField
								component={InputField}
								name="admin_name"
								label="Name"
								placeholder="Admin Name"
								colorLabel='#7A7A7A'
							/>
							<FastField
								component={InputField}
								type="email"
								name="email"
								label="Email"
								placeholder="Email"
								colorLabel='#7A7A7A'
							/>

							<IconTitle text="License"
								icon="clock" />
							<Row className="mt-1">
								<Col lg="6" sm="12">
									<FastField
										component={InputField}
										type="date"
										name="start_date"
										label="Start date"
										placeholder="Start date"
										colorLabel='#7A7A7A'
									/>
								</Col>
								<Col lg="6" sm="12">
									<FastField
										component={InputField}
										type="date"
										name="end_date"
										label="End date"
										placeholder="End date"
										colorLabel='#7A7A7A'
									/>
								</Col>
							</Row>
							<ButtonCustom text='Save'
								outline={true}
								type="submit"
								fullWidth="auto"
								disable={(isValid && isSubmitting) ? true : false} />
						</Form>
					)
				}}
			</Formik>
		</ContainerFormGlobal>
	);
}

export default FormEditClient;