import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconTitle from '../../Common/IconTitle';
import { FastField, Form, Formik } from 'formik';
import InputField from '../../Common/FormikCustomField/InputField';
import SelectField from '../../Common/FormikCustomField/SelectField';
import { Col, Row } from 'reactstrap';
import ButtonCustom from '../../Common/ButtonCustom';
import { Link } from 'react-router-dom';
import { ContainerFormEdit } from './EditFormElement';
import { InitContext } from '../../../contexts/InitProvider';
import { userContext } from '../../../contexts/UserProvider';


EditForm.propTypes = {
	userInfo: PropTypes.object,
};

EditForm.defaultProps = {
	userInfo: {}
}

function EditForm(props) {
	const initState = {
		fullname: '',
		phone: '',
		country: ''
	}

	const [user, setUser] = useState(initState);
	const {changeInfoUser}= useContext(userContext);
	const { getError } = useContext(InitContext);
	const { userInfo } = props;

	useEffect(() => {
		userInfo && setUser({
			fullname: userInfo?.fullname || '',
			phone: userInfo?.phone || '',
			country: userInfo?.country || ''
		}
		)
	}, [userInfo])

	const onEditUserInfo = async (values) => {
		const res = await changeInfoUser(userInfo?.id,values);
		if (!res?.status) {
			getError(res?.messError)
			setTimeout(() => {
				getError(undefined)
			}, 3000);
		}
	}

	return (
		<ContainerFormEdit>
			<div className="d-flex justify-content-center flex-column mt-4 mb-4 pb-3 border-bottom">
				<IconTitle text="Client Admin" icon="user-circle" fontSizeIcon="120px" flexDirection="column" color="black" fontSizeText="24px" />
				<Link to="#" className="text-center">View profile</Link>
			</div>
			<Formik
				initialValues={user}
				enableReinitialize={true}
				onSubmit={values => onEditUserInfo(values)}
			>
				{() => (
					<Form>
						<FastField
							component={InputField}
							name="fullname"
							label="Full Name"
							placeholder="Full Name"
						/>
						<FastField
							component={InputField}
							type="number"
							name="phone"
							label="Phone Number"
							placeholder="Phone Number"
						/>
						<FastField
							component={SelectField}
							name="country"
							label="Country"
							placeholder="Country"
							option={[{ name: 'Viet Nam', value: '1' }, { name: 'Indonesia', value: '2' }, { name: 'USA', value: '3' }]}
						/>
						<Row>
							<Col sm="6" className="mb-3">
								<ButtonCustom text="Update" outline={true} type="submit" />
							</Col>
							<Col sm="6">
								<Link to="/account/general"><ButtonCustom text="Cancel" outline={true} /></Link>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</ContainerFormEdit>
	);
}

export default EditForm;