import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginProvider';
import { InitContext } from '../../contexts/InitProvider';
import ModalCustom from '../../components/Common/ModalCustom';

ActivePage.propTypes = {

};

function ActivePage(props) {
	const { activeCode } = useParams();
	const { activeUser } = useContext(LoginContext);
	const [checkActive, setCheckActive] = useState(false);
	const [modal, setModal] = useState(false);
	const { getError } = useContext(InitContext);

	const toggle = () => setModal(false);

	const active = async (activeCode) => {
		const res = await activeUser(activeCode);
		if (res === true) {
			await setCheckActive(true);
			await setModal(true);
		} else {
			await getError(res?.messError);
			await setTimeout(() => {
				getError(undefined);
			}, 4000)
		}
	}

	useEffect(() => {		
		active(activeCode);
	}, []);


	return (
		<div>
			{checkActive && <ModalCustom stateModal={modal} header="Success" toggle={toggle} textButton="Return to login" linkTo="/login" content="You have successfully activated. Please return login page!!!!" />}
		</div>

	);
}

export default ActivePage;