import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import IconTitle from '../../IconTitle';
import { ContainerRadio } from './RadioFieldElements';
import { ErrorMessage } from 'formik';
import ErrorMessageCustom from '../../ErrorMessageCustom';
import { getIn } from "formik";

RadioField.propTypes = {
	field: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,

	label: PropTypes.string,
	option: PropTypes.array,
	colorLabel: PropTypes.string,
	iconInput: PropTypes.string,
	colorIcon: PropTypes.string,
	disable: PropTypes.bool,
	onHandleChange: PropTypes.func,
	styleRadio: PropTypes.object
};

RadioField.defaultProps = {
	label: '',
	option: [],
	colorLabel: 'rgb(122, 122, 122)',
	iconInput: '',
	colorIcon: '',
	readOnly: false,
	onHandleChange: null,
	styleRadio: {}
}

function RadioField({field, label, option, colorLabel, iconInput, colorIcon, readOnly, form, onHandleChange, styleRadio}) {

	const { name, onChange , value } = field;
	const { errors, touched } = form;

	const showError = getIn(errors, name) && getIn(touched, name);

	return (
		<ContainerRadio>
			{label && <IconTitle text={label} color={colorLabel} icon={iconInput} colorIcon={colorIcon} />}
			<Radio.Group
				{...field}
				name={name}
				value={value}
				onChange={onHandleChange ? onHandleChange : onChange}
				disabled={readOnly}
				className={showError ? 'is-invalid' : ''}
				style={styleRadio}
			>
				{option && option.map((value, idx) => (
					<Radio key={idx} value={value.value}>
						{value.name}
					</Radio>
				))}
			</Radio.Group>
			<ErrorMessage name={name} render={msg => <ErrorMessageCustom errorMessage={msg} />} />
		</ContainerRadio>
	);
}

export default RadioField;