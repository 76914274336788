import React, { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import {GlobalStyle} from './globalStyles';
import './components/Common/FontAnsomeIcons';
import Routes from './routes';
import { LoginContext } from './contexts/LoginProvider';
import AdminLayout from './layouts/AdminLayout';



function App() {
  const { user } = useContext(LoginContext);

  return (
    <div className="App">
      <GlobalStyle />
      <Router>
        <Switch>
          {Routes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              component={() =>
                route.isAdmin && user?.role === "Client" ?
                  <Redirect to="/login" /> :
                  (
                    route.isGlobal && user?.role === "Admin" ?
                      <AdminLayout>
                        <route.component />
                      </AdminLayout> :
                      <route.layout>
                        <route.component />
                      </route.layout>
                  )}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
