
import React, { createContext, useState } from 'react';
import useApiCRUD from '../hooks/useApiCRUD';



export const DownloadRenditionsContext = createContext();

function DownloadRenditionsProvider({ children }) {
	const [renditions, setRenditions] = useState([]);
	const [apiCreate, apiEdit, apiGetList, , apiDelete] = useApiCRUD()

	const editDownloadRenditions = async (id, renditionsInfo) => {
		const url = `/api/download-renditions/${id}`;
		const res = await apiEdit(url, renditionsInfo);
		if (res.status) {
			const RenditionsUpdated = renditions.map(item => item.id === renditionsInfo.id ? { ...item, ...renditionsInfo } : item)
			setRenditions(RenditionsUpdated);
		}
		return res;
	}

	const addDownloadRenditions = async (renditions) => {
		const url = "/api/download-renditions";
		const res = await apiCreate(url, renditions);
		return res;
	}

	const getListRenditions = async (ClientId) => {
		const url = ClientId ? `/api/download-renditions?client_id=${ClientId}` : "/api/download-renditions";
		const res = await apiGetList(url);
		if (res.status) {
			const dataRenditions = (res.data|| []).reduce((acc, cur) => [...acc, {...cur, config: JSON.parse(cur.config) }], [])
			setRenditions(dataRenditions);
		}
		return res;
	}

	const deleteDownloadRendition = async (id) => {
		const url = `/api/download-renditions/${id}`;
		const res = await apiDelete(url);
		if (res.status) {
			const DownloadRenditionsDeleted= renditions.filter(item => item.id !== id);
			setRenditions(DownloadRenditionsDeleted);
		}
		return res;
	}

	return (
		<DownloadRenditionsContext.Provider value={{ renditions, editDownloadRenditions, addDownloadRenditions, getListRenditions, setRenditions, deleteDownloadRendition }}>
			{children}
		</DownloadRenditionsContext.Provider>
	);
}

export default DownloadRenditionsProvider;