import React, { useContext } from 'react';
import { ContainerLayout } from './LayoutNotHeaderElement';
import ErrorMessageGlobal from '../../components/Common/ErrorMessageGlobal'
import { InitContext } from '../../contexts/InitProvider';
import LoadingTemplate from '../../components/Common/LoadingTemplate';

LayoutNotHeader.propTypes = {

};

function LayoutNotHeader({ children }) {
	const { error,loading } = useContext(InitContext)

	return (
		<>		
		{loading && <LoadingTemplate/>}
			<ContainerLayout>
				{error && <ErrorMessageGlobal messError={error} />}
				{children}
			</ContainerLayout>
		</>
	);
}

export default LayoutNotHeader;