import React from 'react';
import PropTypes from 'prop-types';
import FormFeed from '../FormFeeds';
import { ContainerListFeeds } from './ListFeedsElements';

ListFeeds.propTypes = {
	data: PropTypes.array,
	clientId: PropTypes.string,
};

ListFeeds.defaultProps = {
	data: [],
	clientId: ''
}

function ListFeeds(props) {
	const { data,clientId } = props;
	return (
		<ContainerListFeeds>
			{data && data.map((feed, ind) => {
				return (
					<FormFeed key={ind} data={feed} clientId={clientId} />
				)
			})}
		</ContainerListFeeds>
	);
}

export default ListFeeds;