import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from "formik";
import { Input } from 'reactstrap';
import { ErrorMessage } from 'formik';
import ErrorMessageCustom from '../../ErrorMessageCustom';
import IconTitle from '../../IconTitle';

SelectField.propTypes = {
	field: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,

	label: PropTypes.string,
	option: PropTypes.array,
	onHandleChange: PropTypes.func,
	valueType: PropTypes.func,
	colorLabel: PropTypes.string,
	iconInput: PropTypes.string,
	colorIcon: PropTypes.string,
	helpText:PropTypes.string
};

SelectField.defaultProps = {
	label: '',
	option: [],
	onHandleChange: null,
	valueType: (item) => item.value,
	colorLabel: 'rgb(122, 122, 122)',
	iconInput:'',
	colorIcon: '',
	helpText:'',
}

function SelectField(props) {
	const { label, field, form, option, onHandleChange, valueType,colorLabel,iconInput,colorIcon, helpText } = props;
	const [valueSelect,setValueSelect]=useState('');
	const { name, value, onChange } = field
	const { errors, touched } = form;
	
	const showError = getIn(errors, name) && getIn(touched, name);
	return (
		<div style={{marginBottom: '20px'}}>
			{option &&
				<>
					{label && <IconTitle text={label} color={colorLabel} icon={iconInput} colorIcon={colorIcon} />}
					<Input
						type="select"
						value={value}
						{...field}
						invalid={showError}
						onChange={onHandleChange || onChange}
					>
						<option value='' key={0}>Select</option>
						{option && option.map((item, idx) => (<option key={idx} value={valueType(item)}>{item.name}</option>))}
					</Input>
					<ErrorMessage name={name} render={msg => <ErrorMessageCustom errorMessage={msg} />} />
					{helpText && 
					<p style={{color:'#7A7A7A', fontSize:'13px', marginTop:'5px',marginBottom:'0'}}>
						{helpText}
					</p>}
				</>
			}
		</div>
	);
}

export default SelectField;