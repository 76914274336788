import styled from 'styled-components';
import ButtonCustom from '../../Common/ButtonCustom';



export const ContainerFormWebsite = styled.div`
	padding: 25px;
	border: 1px solid #7a7a7a;
	border-radius: 4px;
	& h2 {
		color: rgb(0, 158, 227);
		font-size: 2rem;
		font-weight: 400;
		line-height: 32px;
	}
`


export const ButtonFormWebsite = styled(ButtonCustom)`
	margin-top: 15px;
`