import React, { useContext, useState } from 'react';

import IconTitle from '../Common/IconTitle';
import { FastField, Form, Formik } from 'formik';
import InputField from '../Common/FormikCustomField/InputField';
import ButtonCustom from '../Common/ButtonCustom';
import { ContainerResetPassword } from './PasswordResetFormElements';
import * as Yup from 'yup';
import { LoginContext } from '../../contexts/LoginProvider';
import ModalCustom from '../Common/ModalCustom';
import { InitContext } from '../../contexts/InitProvider';


function PasswordResetForm(props) {

	const { resetPassword } = useContext(LoginContext);
	const [modal, setModal] = useState(false);
	const { getError } = useContext(InitContext);

	const initState = {
		email: ''
	}

	const resetFormSchema = Yup.object().shape({
		email: Yup.string().required("Required").email()
	})

	const toggle = () => setModal(false);
	const onResetPassword = async (values) => {
		const res = await resetPassword(values);
		if (res.status) {
			setModal(true);
		} else {
			getError(res.messError)
			setTimeout(() => getError(undefined), 4000);
		}
	}

	return (
		<ContainerResetPassword>
			<ModalCustom stateModal={modal} toggle={toggle} header="Success" content="Password has been reset! please go to your email and set a new password" />
			<IconTitle icon="bell" colorIcon="#919ca7" fontSizeIcon="50px" position="center" />
			<p className="text-center mt-5 ">
				To reset your password, please enter your email address or username below
			</p>
			<Formik
				initialValues={initState}
				validationSchema={resetFormSchema}
				onSubmit={values => onResetPassword(values)}
			>
				{({ values }) => {
					return (
						<Form>
							<FastField
								component={InputField}
								name="email"
								placeholder="Enter your email"
							/>
							<ButtonCustom text="Reset my password" colorCustom={['#3ba1da', '#44b0ec']} type="submit" />
						</Form>
					)
				}}
			</Formik>
			<ButtonCustom text="Return to login page" colorDefault="link" colorText="rgb(0, 158, 227)" linkTo="/login" />
		</ContainerResetPassword>
	);
}

export default PasswordResetForm;