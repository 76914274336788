import React, { createContext, useState } from 'react';
import useApiCRUD from '../hooks/useApiCRUD';


export const WebsiteContext = createContext();

function WebsiteProvider({ children }) {
	const [websites, setWebsites] = useState([]);
	const [websiteDetails, setWebsiteDetails] = useState();
	const [apiCreate, apiEdit, apiGetList, apiGetOne, apiDelete] = useApiCRUD()


	const addWebsite = async (website) => {
		const url = "/api/websites";
		const res = await apiCreate(url, website);
		return res;
	}

	const getListWebsite = async (clientId) => {
		const url = clientId ? `/api/websites?client_id=${clientId}` : "/api/websites";
		const res = await apiGetList(url);
		res.status &&  setWebsites(res?.data);
		return res;
	}

	const editWebsite = async (id, websiteInfo) => {
		const url = `/api/websites/${id}`;
		const res = await apiEdit(url, websiteInfo);
		return res;
	}

	const getWebsiteDetails = async (id) => {
		const url = `/api/websites/${id}`;
		const res = await apiGetOne(url);
		res.status && setWebsiteDetails(res?.data)
		return res;
	}

	const deleteWebsite = async (id) => {
		const url = `/api/websites/${id}`;
		const res = await apiDelete(url);
		if (res.status) {
			const websitesDeleted=  websites.filter(item => item.id !== id);
			setWebsites(websitesDeleted);
		}
		return res;
	}

	return (
		<WebsiteContext.Provider value={{ websites, websiteDetails, addWebsite, getListWebsite, editWebsite, getWebsiteDetails,deleteWebsite }}>
			{children}
		</WebsiteContext.Provider>
	);
}

export default WebsiteProvider;