import { Col, Input, Label } from 'reactstrap';
import styled from 'styled-components';



export const LabelInput = styled(Label)`
	font-weight: 400;
	${props => props.color && `color : ${props.color}`};
`

export const ColCheckBox = styled(Col)`
	max-width: ${props => props.type === 'checkbox' ? '5%' : 'auto'};
	position: unset;
	 ${props => props.type !== 'checkbox' && `margin-bottom: 5px`};
	 & .form-control[readonly]{		
    background-color: white;
	}
	${props => props.type === 'checkbox' && `& .invalid-feedback {
	width: 96%;
    position: absolute;
	bottom: -40px;
	@media(max-width:500px){
		width: 90%
	}
	}`};

	
`

export const InputCustom = styled(Input)`
	${props => props.type === 'checkbox' && `margin-left: 0`};
	
`

export const ContainerInputField= styled.div`	
	margin-bottom:20px;
`