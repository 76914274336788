import React from 'react';
import FormAddClient from '../../../components/Clients/FormAddClient';



AddNewClients.propTypes = {

};

function AddNewClients(props) {
	return (		
		<FormAddClient />
	);
}

export default AddNewClients;