import { FastField, Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";
import InputField from "../../Common/FormikCustomField/InputField";
import RadioField from "../../Common/FormikCustomField/RadioField";
import SelectField from "../../Common/FormikCustomField/SelectField";
import IconTitle from "../../Common/IconTitle";
import { ContainerForm } from "../FormAddEditFeedElements";

Downloadadble.propTypes = {
    optionDownloadableImage: PropTypes.array,
    filterOptionRenditions: PropTypes.array,
};

Downloadadble.defaultProps = {
    optionDownloadableImage: [],
    filterOptionRenditions: [],
};

function Downloadadble(props) {
    const { optionDownloadableImage, filterOptionRenditions } = props;
    const { values } = useFormikContext();

    return (
        <div>
            <ContainerForm>
                <IconTitle fontSizeText="30px" text="Downloadable Products" />
                <FastField
                    component={RadioField}
                    name="downloadadble_products.is_downloadable"
                    label="Product is downloadable"
                    option={[
                        {
                            value: true,
                            name: "Yes",
                        },
                        {
                            value: false,
                            name: "No",
                        },
                    ]}
                />
                {values.downloadadble_products.is_downloadable && (
                    <>
                        <Row>
                            <Col sm="3">
                                <FastField
                                    component={RadioField}
                                    name="downloadadble_products.is_virtual"
                                    label="Product is virtual"
                                    option={[
                                        {
                                            value: true,
                                            name: "Yes",
                                        },
                                        {
                                            value: false,
                                            name: "No",
                                        },
                                    ]}
                                />
                            </Col>
                            <Col sm="3">
                                <FastField
                                    component={RadioField}
                                    name="downloadadble_products.is_sold_individually"
                                    label="Sold individually"
                                    option={[
                                        {
                                            value: true,
                                            name: "Yes",
                                        },
                                        {
                                            value: false,
                                            name: "No",
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <div style={{ marginBottom: "-12px" }}>
                            <IconTitle
                                className="mt-3"
                                fontSizeText="20px"
                                text="Downloadable Images"
                            />
                        </div>
                        <ContainerForm>
                            <Row>
                                <Col sm="4">
                                    <Field
                                        component={SelectField}
                                        name="downloadadble_products.downloadable_images.name_downloadable_image"
                                        label="Name"
                                        option={optionDownloadableImage}
                                        helpText="This is name of the download show the customer"
                                    />
                                </Col>
                                <Col sm="4">
                                    <Field
                                        component={SelectField}
                                        name="downloadadble_products.downloadable_images.url_downloadable_image"
                                        label="URL"
                                        // value={
                                        // 	values.download_rendition_id ||
                                        // 	filterOptionRenditions[0]?.id
                                        // }
                                        valueType={(item) => item.id}
                                        option={filterOptionRenditions}
                                        helpText="Only download renditions with no expiration time, 'valid for' is set to -1, will be shown here"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="4">
                                    <FastField
                                        component={InputField}
                                        name="downloadadble_products.downloadable_images.limit_downloadable_image"
                                        placeholder="Unlimited"
                                        label="Download limit"
                                        type="number"
                                        option={[
                                            {
                                                value: true,
                                                name: "Yes",
                                            },
                                            {
                                                value: false,
                                                name: "No",
                                            },
                                        ]}
                                        helpText="leave blank for unlimited re-downloads"
                                    />
                                </Col>
                                <Col sm="4">
                                    <FastField
                                        component={InputField}
                                        name="downloadadble_products.downloadable_images.expiry_downloadable_image"
                                        placeholder="Never"
                                        label="Download expiry"
                                        type="number"
                                        option={[
                                            {
                                                value: true,
                                                name: "Yes",
                                            },
                                            {
                                                value: false,
                                                name: "No",
                                            },
                                        ]}
                                        helpText="Enter the number of days before a download link expipres, or leave blank"
                                    />
                                </Col>
                            </Row>
                        </ContainerForm>

                        <div style={{ marginBottom: "-12px" }}>
                            <IconTitle
                                className="mt-3"
                                fontSizeText="20px"
                                text="Other downloadable files (non-images)"
                            />
                        </div>
                        <ContainerForm>
                            <Row>
                                <Col sm="4">
                                    <Field
                                        component={SelectField}
                                        name="downloadadble_products.other_downloadable_file.name_downloadable_file"
                                        label="Name"
                                        option={optionDownloadableImage}
                                        helpText="This is name of the download show the customer"
                                    />
                                </Col>
                                <Col sm="4">
                                    <FastField
                                        component={InputField}
                                        name="downloadadble_products.other_downloadable_file.url_downloadable_file"
                                        label="URL"
                                        readOnly={true}
                                        option={[
                                            {
                                                value: true,
                                                name: "Yes",
                                            },
                                            {
                                                value: false,
                                                name: "No",
                                            },
                                        ]}
                                        helpText="Only download renditions with no expiration time, 'valid for' is set to -1, will be shown here"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="4">
                                    <FastField
                                        component={InputField}
                                        name="downloadadble_products.other_downloadable_file.limit_downloadable_file"
                                        placeholder="Unlimited"
                                        label="Download limit"
                                        type="number"
                                        option={[
                                            {
                                                value: true,
                                                name: "Yes",
                                            },
                                            {
                                                value: false,
                                                name: "No",
                                            },
                                        ]}
                                        helpText="Leave blank for unlimited re-downloads"
                                    />
                                </Col>
                                <Col sm="4">
                                    <FastField
                                        component={InputField}
                                        name="downloadadble_products.other_downloadable_file.expiry_downloadable_file"
                                        label="Download expiry"
                                        placeholder="Never"
                                        type="number"
                                        option={[
                                            {
                                                value: true,
                                                name: "Yes",
                                            },
                                            {
                                                value: false,
                                                name: "No",
                                            },
                                        ]}
                                        helpText="Enter the number of days before a download link expipres, or leave blank"
                                    />
                                </Col>
                            </Row>
                        </ContainerForm>
                    </>
                )}
            </ContainerForm>
        </div>
    );
}

export default Downloadadble;
