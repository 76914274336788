import styled from 'styled-components';
import { TitleWrapper } from '../../Websites/Feeds/FormFeeds/FormFeedElements';

export const ContainerFormDownloadRenditions = styled.div`
  border: 1px solid #7a7a7a;
  padding: 25px;
  border-radius: 3px;
  margin-bottom: 30px;
  & form > .row {
    margin-bottom: 10px;
  }
  & h2 {
    color: #009ee3;
    font-size: 30px;
    font-weight: 400;
  }
`;


export const TitleRendition = styled(TitleWrapper)`
 & input{
    color: #009ee3;
    font-size: 30px;
    font-weight: 400;
    ${props => props.isEdit && `border: none; padding:0`}
 }
 & .form-control[readonly]:focus {
    border: none;
    color: #009ee3
 }

`
