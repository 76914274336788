
import React, { createContext, useState } from 'react';
import useApiCRUD from '../hooks/useApiCRUD';



export const WoodWingAssetsContext = createContext();

function WoodWingAssetsProvider({ children }) {
	const [woodWingAssets, setWoodWingAssets] = useState([]);
	const [apiCreate, apiEdit, apiGetList, , apiDelete] = useApiCRUD()

	const editWoodWingAsset = async (id, woodWingAssetsInfo) => {
		const url = `/api/wwa-servers/${id}`;
		const res = await apiEdit(url, woodWingAssetsInfo);
		if (res.status) {
			const woodWingAssetsUpdated = woodWingAssets.map(item => item.id === woodWingAssetsInfo.id ? { ...item, ...woodWingAssetsInfo } : item)
			setWoodWingAssets(woodWingAssetsUpdated);
		}
		return res;
	}


	const addWoodWingAsset = async (ClientId, woodWingAssets) => {
		const url = ClientId ? `/api/wwa-servers?client_id=${ClientId}` : "/api/wwa-servers";
		const res = await apiCreate(url, woodWingAssets);
		return res;
	}

	const getListWoodWingAssets = async (ClientId) => {
		const url = ClientId ? `/api/wwa-servers?client_id=${ClientId}` : "/api/wwa-servers";
		const res = await apiGetList(url);
		if (res.status) {
			const allValue=res.data?.map((item,ind) =>{
				return {...item,metadata_field: JSON.parse(item.metadata_field)};
			})
			setWoodWingAssets(allValue);
		}
		return res;
	}

	const deleteWoodWingAsset = async (id) => {
		const url = `api/wwa-servers/${id}`
		const res = await apiDelete(url);
		if (res.status) {
			const woodWingAssetsDeleted = woodWingAssets.filter(item => item.id !== id);
			setWoodWingAssets(woodWingAssetsDeleted);
		}
		return res;
	}

	return (
		<WoodWingAssetsContext.Provider value={{ woodWingAssets, getListWoodWingAssets, addWoodWingAsset, editWoodWingAsset, deleteWoodWingAsset }}>
			{children}
		</WoodWingAssetsContext.Provider>
	);
}

export default WoodWingAssetsProvider;