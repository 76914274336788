import { Alert } from 'antd';
import styled from "styled-components";



export const AlertError = styled(Alert)`
		position: fixed;
  		top: 5%;
  		left: 50%;
  		margin-left: -100px;
  		z-index: 1000;

`