import { Container } from 'reactstrap';
import styled from 'styled-components';
import { keyframes } from 'styled-components';


const shake = keyframes`
	 10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }
   
  20%, 80% {
    transform: translate3d(3px, 0, 0);
  }
 
  30%, 50%, 70% {
    transform: translate3d(-5px, 0, 0);
  }
 
  40%, 60% {
    transform: translate3d(5px, 0, 0);
  }

`


export const ContainerResetPassword = styled(Container)`

& .animate:hover{			
	animation-name: ${shake};	
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
	
	}

	& p {
		color: rgb(119, 119, 119);
		font-size: 17px;
	}
`