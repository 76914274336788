import React, { useContext } from 'react';
import { FastField, Form, Formik } from 'formik';
import InputField from '../../Common/FormikCustomField/InputField';
import ButtonCustom from '../../Common/ButtonCustom';
import * as Yup from 'yup';
import { userContext } from '../../../contexts/UserProvider';
import { InitContext } from '../../../contexts/InitProvider';
import { LoginContext } from '../../../contexts/LoginProvider';

ChangePasswordAccount.propTypes = {

};

function ChangePasswordAccount(props) {
	const { changePassword } = useContext(userContext)
	const { getError } = useContext(InitContext);
	const { setToken } = useContext(LoginContext);
	const initState = {
		current_password: '',
		new_password: '',
		confirm_password: ''
	}

	const schema = Yup.object().shape({
		current_password: Yup.string().required('Current Password is required field'),
		new_password: Yup.string().required('Password is required field')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number"
			),
		confirm_password: Yup.string()
			.oneOf([Yup.ref('new_password'), null], 'Confirm password must match with password')
			.required('Confirm password is required field'),
	})

	const onChangePassword = async (values) => {
		const res = await changePassword(values);
		if (!res.status) {
			getError(res?.messError)
			setTimeout(() => {
				getError(undefined)
			}, 3000);
		} else {
			setToken(res?.token);
		}
	}

	return (
		<div>
			<Formik
				initialValues={initState}
				enableReinitialize={true}
				validationSchema={schema}
				onSubmit={values => onChangePassword(values)}
			>
				{({isValid,isSubmitting}) => (
					<Form>
						<FastField
							component={InputField}
							name="current_password"
							type="password"
							label="Current Password"
							placeholder="Current Password"
						/>
						<FastField
							component={InputField}
							name="new_password"
							type="password"
							label="New Password"
							placeholder="New Password"
						/>
						<FastField
							component={InputField}
							name="confirm_password"
							type="password"
							label="Confirm Password"
							placeholder="Confirm Password"
						/>
						<ButtonCustom text="Update Password" outline={true} fullWidth="auto" type="submit" isLoading={(isValid && isSubmitting) ? true : false} />
					</Form>

				)
				}
			</Formik>
		</div>
	);
}

export default ChangePasswordAccount;