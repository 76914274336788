import React from 'react';
import { ContainerErrorMes, IconArrow } from './ErrorMessageCustomElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ErrorMessageCustom({ errorMessage }) {
	return (
		<ContainerErrorMes>
			<IconArrow><FontAwesomeIcon icon="caret-up"/></IconArrow>
			{errorMessage}
		</ContainerErrorMes>
		
	);
}

export default ErrorMessageCustom;