import React, { createContext, useEffect, useState } from 'react';
import axiosClient from '../apis/axiosClient';
import loginApi from '../apis/loginApi';
import config from '../config';
import { ConverDataEncrypt } from '../utils/ConverDataEncrypt';


export const LoginContext = createContext();

function LoginProvider({ children }) {
  const [user, setUser] = useState();
  //const [token, setToken] = useState(localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token'));
  const [token, setToken] = useState(localStorage.getItem('token'));

  const infoLogin = (user) => {
    return {
      grant_type: config.apiGrantType,
      scope: '',
      client_secret: config.apiClientSecret,
      client_id: config.apiClientId,
      email: user.email,
      password: ConverDataEncrypt.encrypt(user.password),
    };
  }

  useEffect(() => {
    const requestInterceptor = axiosClient.interceptors.request.use(async (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    const responseInterceptor = axiosClient.interceptors.response.use(
      (response) => {
        if (response && response.data) {
          return response.data;
        }

        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          setToken(undefined);
          return error.response;
        }
        throw error;
      }
    );

    if (token) {
      const getUser = async () => {
        const user = await loginApi.getInfoUser(token);
        if (user.status) {
          setUser(user.data);
          localStorage.getItem('token') && localStorage.setItem('token', token);
          //sessionStorage.getItem('token') && sessionStorage.setItem('token', token);
        }
      }
      getUser();

    } else {
      setUser(undefined);
      localStorage.removeItem('token');
      //sessionStorage.removeItem('token');
    }

    return () => {
      axiosClient.interceptors.request.eject(requestInterceptor);
      axiosClient.interceptors.response.eject(responseInterceptor);
    }
  }, [token]);

  const login = async (user) => {
    token && setToken(undefined);
    try {
      const response = await loginApi.getToken(infoLogin(user));
      if (response.status) {
        setToken(response.data.access_token);
        localStorage.setItem('token', response.data.access_token)
        //user.remember ? localStorage.setItem('token', response.data.access_token) : sessionStorage.setItem('token', response.data.access_token);
        
        return { status: true }
      }
    } catch (error) {
      return { status: false, messError: error.response };
    }
  };

  //Action register
  const register = async (userRegister) => {
    try {
      const infoRegister = {
        fullname: userRegister.fullName,
        email: userRegister.email,
        // phoneNumber: userRegister.phoneNumber,
        password: ConverDataEncrypt.encrypt(userRegister.password),
        role: 'Admin',
      };
      const response = await loginApi.register(infoRegister);
      if (response.status) {

        return { status: true };
      }
    } catch (error) {
      console.log('Fail to Register', error);
      return { status: false, messError: error.response.data.email[0] }
    }
  };

  //Action sign out
  const signOut = () => {
    setToken(undefined);
  }

  const activeUser = async (code) => {
    try {
      let info = {
        code: code
      }
      let response = await loginApi.activeUser(info);
      if (response.status) {
        return true;
      }
    } catch (error) {
      console.log('Fail to Active', error.response.data.message);
      return { status: false, messError: error.response.data.message }
    }
  }

  const resetPassword = async (email) => {
    try {
      let response = await loginApi.resetPassword(email);
      if (response.status) {
        return { status: true };
      }
    } catch (error) {
      console.log('Fail to Active', error.response.data.message);
      return { status: false, messError: error.response.data.message }
    }
  }

  const renewPassword = async (newPassword) => {
    try {
      let response = await loginApi.renewPassword(newPassword);
      if (response.status) {
        return { status: true };
      }
    } catch (error) {
      console.log('Fail to Active', error.response.data.message);
      return { status: false, messError: error.response.data.message }
    }
  }




  return (
    <LoginContext.Provider value={{ user, token, setToken, login, register, signOut, activeUser, resetPassword, renewPassword }}>
      {children}
    </LoginContext.Provider>
  );
}

export default LoginProvider;
