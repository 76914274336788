import styled from "styled-components";



export const ContainerLoading = styled.div`
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	background-color: #f2f2f2;
	z-index: 999;
`