import { FastField, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ButtonCustom from "../../../Common/ButtonCustom";
import InputField from "../../../Common/FormikCustomField/InputField";
import IconTitle from "../../../Common/IconTitle";
import { ContainerFeed, TitleFeed, TitleWrapper } from "./FormFeedElements";

FormFeed.propTypes = {
    data: PropTypes.object,
    clientId: PropTypes.string,
};

FormFeed.defaultProps = {
    data: {},
    clientId: "",
};

function FormFeed(props) {
    const initState = {
        name: "",
        url: "",
        server_query: "",
        webhook: "",
        post_type: "",
    };
    const [feed, setFeed] = useState(initState);
    const { data, clientId } = props;

    useEffect(() => {
        if (data) {
            const dataFeed = {
                name: data.wwa_server.name,
                url: data.wwa_server.url,
                server_query: data.server_query,
                post_type: data.post_type,
                webhook: data.webhook == 1 ? "Active" : "Inactive",
            };
            setFeed(dataFeed);
        }
    }, [data]);

    return (
        <ContainerFeed activeHook={feed.webhook}>
            <TitleWrapper>
                <TitleFeed>{data && data.name}</TitleFeed>
                <div className="text-sm-right button-feed">
                    <ButtonCustom
                        text="Edit Feed"
                        outline={true}
                        colorDefault="primary"
                        linkTo={
                            clientId
                                ? `/client/${clientId}/edit-feed/${data.id}`
                                : `/edit-feed/${data.id}`
                        }
                    />
                </div>
            </TitleWrapper>
            <Formik initialValues={feed} enableReinitialize={true}>
                {({ values }) => {
                    return (
                        <Form style={{ marginTop: "20px" }}>
                            <IconTitle
                                text="WoodWing Assets"
                                icon="database"
                                fontSizeText="20px"
                                fontSizeIcon="14px"
                            />
                            <FastField
                                component={InputField}
                                name="name"
                                label="Server name"
                                colorLabel="rgb(122, 122, 122)"
                                readOnly={true}
                            />
                            <FastField
                                component={InputField}
                                name="url"
                                label="URL"
                                colorLabel="rgb(122, 122, 122)"
                                readOnly={true}
                            />
                            <IconTitle
                                text="Source Files"
                                icon="images"
                                fontSizeText="20px"
                                fontSizeIcon="14px"
                            />
                            <FastField
                                component={InputField}
                                type="textarea"
                                name="server_query"
                                label="Search Query"
                                colorLabel="rgb(122, 122, 122)"
                                readOnly={true}
                            />
                            <IconTitle
                                text="Post Type"
                                icon="file-code"
                                fontSizeText="20px"
                                fontSizeIcon="14px"
                            />
                            <FastField
                                component={InputField}
                                name="post_type"
                                label="Type"
                                colorLabel="rgb(122, 122, 122)"
                                readOnly={true}
                            />
                            <IconTitle
                                text="Webhook"
                                icon="clock"
                                fontSizeText="20px"
                                fontSizeIcon="14px"
                            />
                            <FastField
                                component={InputField}
                                name="webhook"
                                label="Status"
                                colorLabel="rgb(122, 122, 122)"
                                readOnly={true}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ContainerFeed>
    );
}

export default FormFeed;
