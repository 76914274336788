import React from 'react';
import ClientsProvider from './ClientsProvider';
import DownloadRenditionsProvider from './DownloadRenditionsProvider';
import FeedProvider from './FeedProvider';
import InitProvider from './InitProvider';
import LoginProvider from './LoginProvider';
import UserProvider from './UserProvider';
import WebsiteProvider from './WebsiteProvider';
import WoodWingAssetsProvider from './WoodWingAssetsProvider';


function GlobalProvider({ children }) {

	return (
		<LoginProvider>
			<InitProvider>
				<ClientsProvider>
					<WebsiteProvider>
						<FeedProvider>
							<DownloadRenditionsProvider>
								<WoodWingAssetsProvider>
									<UserProvider>
										{children}
									</UserProvider>
								</WoodWingAssetsProvider>
							</DownloadRenditionsProvider>
						</FeedProvider>
					</WebsiteProvider>
				</ClientsProvider>
			</InitProvider>
		</LoginProvider>
	);
}

export default GlobalProvider;