import React, { useContext } from 'react';
import EditForm from '../../../components/AccountForm/EditForm';
import { userContext } from '../../../contexts/UserProvider';
import { ContainerAccount } from '../AccountElements';

function EditFormAccount(props) {
	const {userInfo}=useContext(userContext)
	return (
		<ContainerAccount>
			<div>
				<EditForm userInfo={userInfo} />
			</div>
		</ContainerAccount>
	);
}

export default EditFormAccount;