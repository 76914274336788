import { Container, Row } from 'reactstrap';
import styled from 'styled-components';


export const ContainerRegister = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 15px;
`

export const IconBlock = styled(Row)`
@media(max-width: 992px){
    & > div{
    margin-bottom: 15px;
    margin-top:15px;
    }
}
`