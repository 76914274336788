import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import NavbarBottomHeader from '../../../components/Common/NavbarBottomHeader';
import FormDownloadRenditions from '../../../components/DownloadRenditions/FormDownloadRenditions';
import ListFormWebsites from '../../../components/Websites/ListFormWebsites';
import { DownloadRenditionsContext } from '../../../contexts/DownloadRenditionsProvider';
import { InitContext } from '../../../contexts/InitProvider';
import { LoginContext } from '../../../contexts/LoginProvider';


HomeRenditions.propTypes = {};

function HomeRenditions(props) {
  const { clientId } = useParams();
  const history = useHistory();
  const { user } = useContext(LoginContext);
  const { getError } = useContext(InitContext);
  const { getListRenditions, renditions } = useContext(DownloadRenditionsContext);

  // const getData = async (clientId) => {
  //   if ((user.role === "Admin" && clientId) || (user.role === "Client" && !clientId)) {
  //     const resRenditions = await getListRenditions(clientId)
  //     user.role === "Admin" && await getClientDetails(clientId)    
  //     if (!resRenditions.status) {
  //       getError(resRenditions?.messError);
  //       setTimeout(() => {
  //         getError(undefined)
  //       }, 3000);
  //     }
  //   }
  //   else {
  //     user.role === "Admin" ? history.push("/client") : history.push("/")
  //   }
  // }

  const getData = async (clientId) => {		
    const res = await getListRenditions(clientId)
    if (!res.status) {
      getError(res?.messError);
      setTimeout(() => {
        getError(undefined)
      }, 3000);
    }
}

  useEffect(() => {
    if (user) {
			(user.role === "Admin" && !clientId) ? history.push("/client") : getData(clientId);
		}
  }, [user])

  
  return (
    <Row>
      <Col sm='12'>
        <NavbarBottomHeader className="sticky_header" clientSelected={(renditions) ? renditions[0]?.client?.name : ''} title="Download Renditions" titleButton="Add New Rendition" linkTo={clientId ? `/client/${clientId}/add-download-renditions` : "/add-download-renditions"} />
        <ListFormWebsites
          data={renditions}
          childComponent={(item) => <FormDownloadRenditions data={item} clientId={clientId} />}
        />
      </Col>
    </Row>
  );
}

export default React.memo(HomeRenditions);
