import React from 'react';
import { useParams } from 'react-router-dom';
import FormAddWebsite from '../../../components/Websites/FormAddWebsite';

AddWebsite.propTypes = {

};

function AddWebsite(props) {
	const { clientId } = useParams();

	return (
		 <FormAddWebsite client_id={clientId} />
	);
}

export default AddWebsite;