import CryptoJS from 'crypto-js';

export const ConverDataEncrypt = {
	encrypt: function (string) {
		const wordArray = CryptoJS.enc.Utf8.parse(string);
		const base64 = CryptoJS.enc.Base64.stringify(wordArray);
		return base64
	},
	decrypted: function (base64Encrypt) {
		const parsedWordArray = CryptoJS.enc.Base64.parse(base64Encrypt);
		const parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
		return parsedStr
	}

}
