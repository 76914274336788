import React from 'react';
import PropTypes from 'prop-types';
import { AlertError } from './ErrorMessageGlobalElement';

ErrorMessageGlobal.propTypes = {
	messError: PropTypes.string,
};

ErrorMessageGlobal.defaultProps = {
	messError: ''
}

function ErrorMessageGlobal({ messError }) {
	return (
		<AlertError
		showIcon
		description={ messError }
		type="error"
		closable
	  />
	);
}

export default ErrorMessageGlobal;