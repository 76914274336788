import React, { useContext, useState } from 'react';
import { FastField, Form, Formik } from 'formik';
import InputField from '../../components/Common/FormikCustomField/InputField';
import ButtonCustom from '../../components/Common/ButtonCustom';
import * as Yup from "yup";
import { useParams } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginProvider';
import { ContainerResetPassword } from './ResetPasswordElements'
import IconTitle from '../../components/Common/IconTitle';
import ModalCustom from '../../components/Common/ModalCustom';
import { InitContext } from '../../contexts/InitProvider';


function ResetPassword(props) {
	const { activeCode } = useParams();
	const { renewPassword } = useContext(LoginContext)
	const [modal, setModal] = useState(false);
	const { getError } = useContext(InitContext);
	const initState = {
		password: "",
		confirmPassword: ""
	}

	const resetPasswordSchema = Yup.object().shape({
		password: Yup.string().required('Password is required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number"
			),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Confirm password must match with password')
			.required('Confirm password is required')
	})

	const toggle = () => setModal(false);
	const onResetPassword = async (values) => {
		if (activeCode) {
			const data = {
				code: activeCode,
				password: values?.password
			}
			const res = await renewPassword(data);
			if (res.status) {
				setModal(true);
			} else {
				getError(res?.messError)
				setTimeout(() => {
					getError(undefined)
				}, 3000);
			}
		}
	}
	
	return (
		<ContainerResetPassword>
			<ModalCustom stateModal={modal} toggle={toggle} content="You have successfully activated. Please return login page!!!!" textButton="Link to login" linkTo="/login" />
			<div>
				{/* {test()} */}
				<Formik
					initialValues={initState}
					validationSchema={resetPasswordSchema}
					onSubmit={values => onResetPassword(values)}
				>
					{() => {
						return (
							<Form>
								<IconTitle text="Change Password" icon="asterisk" fontSizeText="1.2rem" fontSizeIcon="1.8rem" colorIcon="rgb(85, 85, 85)" color="rgb(85, 85, 85)" />
								<FastField
									component={InputField}
									type="password"
									name="password"
									label="New Password"
									placeholder="New Password"
								/>
								<FastField
									component={InputField}
									type="password"
									name="confirmPassword"
									label="Confirm Password"
									placeholder="Confirm Password"
								/>
								<ButtonCustom text="Update Password" outline={true} fullWidth="auto" type="submit" />
							</Form>
						)
					}}
				</Formik>
			</div>
		</ContainerResetPassword>
	);
}

export default ResetPassword;