import React, { useContext, useEffect, useState } from "react";
import { DownloadRenditionsContext } from "../../../contexts/DownloadRenditionsProvider";
import FormAddEditFeed from "../../../components/FormAddEditFeed";
import { LoginContext } from "../../../contexts/LoginProvider";
import { dataDefaultWc } from "../../../data/Metadata";
import { WoodWingAssetsContext } from "../../../contexts/WoodWingAssetsProvider";
import { useParams } from "react-router-dom";
import { WebsiteContext } from "../../../contexts/WebsiteProvider";

AddFeed.propTypes = {};

function AddFeed(props) {
    const { websiteId } = useParams();
    const { renditions, getListRenditions } = useContext(
        DownloadRenditionsContext
    );
    const { woodWingAssets, getListWoodWingAssets } = useContext(
        WoodWingAssetsContext
    );
    const { getWebsiteDetails, websiteDetails } = useContext(WebsiteContext);
    const { token, user } = useContext(LoginContext);
    const { clientId } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setIsLoading(false);
        await getListRenditions(clientId);
        await getListWoodWingAssets(clientId);
        websiteId && (await getWebsiteDetails(websiteId));
    };

    useEffect(() => {
        if (token && user) {
            let mounted = true;
            getData().then(() => mounted && setIsLoading(true));
            return () => (mounted = false);
        }
    }, [token, user]);

    return (
        <div>
            {isLoading && (
                <FormAddEditFeed
                    renditions={renditions}
                    dataSelectFieldWC={dataDefaultWc}
                    dataWwa={woodWingAssets}
                    website={websiteDetails}
                />
            )}
        </div>
    );
}

export default AddFeed;
