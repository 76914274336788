import styled, { createGlobalStyle } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
	*{
		/* box-sizing: border-box;
		margin:0;
		padding:0;
		font-size: 20px;
		font-weight: 400; */
		font-family: 'Lato', sans-serif;
		font-weight: 400;
		& .form-control[readonly]:focus 
		{ 
			background-color: white;
			 box-shadow: none; /* border: none; */
			 border: 1px solid #ced4da;
		}
		& .ant-radio-disabled span.ant-radio-inner{
			border-color: #1890ff!important;
		}
		& .ant-radio-disabled .ant-radio-inner::after{
			background-color:#1890ff;
		}
		& .btn-outline-primary:hover{
			background-color: #009ee3;
			border-color:#009ee3;
		}
	}
`;


export const ContainerFormGlobal = styled.div`
  border: 1px solid rgb(122, 122, 122);
  padding: 25px;
  border-radius: 4px;
  margin: 1rem 0;
  & form {
	  & button{
		  margin-top: 10px;
	  }
	& > .row{
	  margin-top: 25px;
  }
  }
`



