import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalContainer } from './ModalElement';
import ButtonCustom from '../ButtonCustom';
import { Link } from 'react-router-dom';


ModalCustom.propTypes = {
	header: PropTypes.string,

	stateModal: PropTypes.bool.isRequired,
	toggle: PropTypes.func,
	content: PropTypes.string,
	textButton: PropTypes.string,
	linkTo: PropTypes.string,
};

ModalCustom.defaultProps = {
	header: 'notification',
	toggle: null,
	content: 'Congratulation ! You have successfully registered, please activate your account',
	linkTo: '',
	textButton: ''
}

function ModalCustom({ stateModal, toggle, header, content, textButton, linkTo, onClick }) {
	const [modal, setModal] = useState(stateModal);

	useEffect(() => {
		setModal(stateModal)
	}, [stateModal])
	

	return (
		<ModalContainer isOpen={modal} >
			<ModalHeader toggle={toggle} >
				{header}
			</ModalHeader>
			<ModalBody>
				{content}
			</ModalBody>
			{textButton &&
				<ModalFooter>
					{linkTo ?
						<Link to={linkTo}><ButtonCustom text={textButton} outline={true} fullWidth="auto" /></Link>
						: <ButtonCustom text={textButton} outline={true} fullWidth="auto" onClick={onClick} />}
						<ButtonCustom text="Cancel" outline={true} fullWidth="auto" onClick={toggle}/>
				</ModalFooter>
			}
		</ModalContainer>
		// <ModalContainer
		// 	visible={stateModal}
		// 	title="Title"
		// 	//   onOk={this.handleOk}
		// 	onCancel={toggle}
		// 	footer={
		// 		(linkTo ? [
		// 			<Link to="/"><Button key="submit" type="primary" size="large" key={0}>
		// 				{textButton}
		// 			</Button></Link>
		// 		] : [<Button key="submit" type="primary" size="large" onClick={toggle} key={0}>
		// 				{textButton}
		// 			</Button>])
		// 	}
		// >
		// 	<h5>{content}</h5>
		// </ModalContainer>
	);
}

export default ModalCustom;