import React, { memo, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ButtonCustom from "../../Common/ButtonCustom";
import { Col, Row } from "reactstrap";
import { FastField, Form, Formik } from "formik";
import RadioField from "../../Common/FormikCustomField/RadioField";
import InputField from "../../Common/FormikCustomField/InputField";
import {
    ContainerFormDownloadRenditions,
    TitleRendition,
} from "./DownloadRenditionsElements";
import { DownloadRenditionsContext } from "../../../contexts/DownloadRenditionsProvider";
import AlertLeavePage from "../../Common/AlertLeavePage";
import { InitContext } from "../../../contexts/InitProvider";

FormDownloadRenditions.propTypes = {
    data: PropTypes.object,
};

FormDownloadRenditions.defaultProps = {
    data: {},
};

function FormDownloadRenditions(props) {
    const { editDownloadRenditions } = useContext(DownloadRenditionsContext);
    const [isGetted, setIsGetted] = useState(false);
    const { getError } = useContext(InitContext);

    const initState = {
        name: "Rendition",
        renditions_key: "",
        config: {
            format: "",
            embedMetadata: 0,
            watermarked: 0,
            embedColorProfile: 0,
            quality: 0,
            ppi: "",
            compression: "",
            scale: "",
            maxWidth: 0,
            maxHeight: 0,
            background: "",
            validFor: 600,
        },
    };
    const { data } = props;
    const [renditions, setRenditions] = useState(initState);

    useEffect(() => {
        if (data) {
            setRenditions(data);
        }
    }, [data]);

    const onEditRenditions = async (values) => {
        if (data) {
            const dataForm = { ...values };
            const dataEdit = Object.assign(dataForm, {
                client_id: data.client_id,
            });
            if (dataEdit?.config?.format === "jpg")
                dataEdit.config.background = "";
            const res = await editDownloadRenditions(data.id, dataEdit);
            if (res.status) {
                setIsGetted(!isGetted);
            } else {
                getError(res?.messError);
                setTimeout(() => {
                    getError(undefined);
                }, 3000);
            }
        }
    };

    return (
        <ContainerFormDownloadRenditions>
            <Formik
                initialValues={renditions}
                enableReinitialize={true}
                onSubmit={(values) => onEditRenditions(values)}
            >
                {({ isSubmitting, setSubmitting, values, dirty }) => {
                    return (
                        <Form>
                            <AlertLeavePage
                                isOpen={dirty}
                                isGetted={isGetted}
                            />
                            <Row>
                                <Col>
                                    <TitleRendition isEdit={!isSubmitting}>
                                        <FastField
                                            component={InputField}
                                            name="name"
                                            readOnly={!isSubmitting}
                                        />

                                        <ButtonCustom
                                            text="Edit"
                                            outline={true}
                                            colorDefault="primary"
                                            onClick={() =>
                                                setSubmitting(!isSubmitting)
                                            }
                                        />
                                    </TitleRendition>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="renditions_key"
                                        readOnly={!isSubmitting}
                                        label="Renditions Key"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={RadioField}
                                        name="config.format"
                                        label="Format"
                                        colorLabel="#7A7A7A"
                                        option={[
                                            { value: "jpg", name: "jpg" },
                                            //{ value: 'tiff', name: 'tiff' },
                                            { value: "png", name: "png" },
                                        ]}
                                        readOnly={!isSubmitting}
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={RadioField}
                                        name="config.watermarked"
                                        label="Watermarked"
                                        colorLabel="#7A7A7A"
                                        option={[
                                            { value: 1, name: "yes" },
                                            { value: 0, name: "no" },
                                        ]}
                                        readOnly={!isSubmitting}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    {values.config.format === "jpg" ? (
                                        <FastField
                                            component={RadioField}
                                            name="config.embedMetadata"
                                            label="Embed metadata"
                                            colorLabel="#7A7A7A"
                                            option={[
                                                { value: 1, name: "yes" },
                                                { value: 0, name: "no" },
                                            ]}
                                            readOnly={!isSubmitting}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={RadioField}
                                        name="config.embedColorProfile"
                                        label="Embed color profile"
                                        colorLabel="#7A7A7A"
                                        option={[
                                            { value: 1, name: "yes" },
                                            { value: 0, name: "no" },
                                        ]}
                                        readOnly={!isSubmitting}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="config.quality"
                                        label="Quality"
                                        colorLabel="#7A7A7A"
                                        readOnly={!isSubmitting}
                                        helpText="JPEG compression. A value from 1 to 100. 100 being the highest quality. 
                      50 is recommended for preview images"
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="config.ppi"
                                        label="PPI"
                                        colorLabel="#7A7A7A"
                                        readOnly={!isSubmitting}
                                        helpText="The pixels per inch / dots per inch can be set to any desired value.
                     When not set, the original PPI / DPI value of the asset will be used."
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="config.maxWidth"
                                        label="Maximum width(px)"
                                        helpText="Sets the maximum width of the preview. The aspect ratio will always remain the same. 
                    If the original asset fits within the supplied dimensions, the original width and height will be used for
                    generating the rendition."
                                        colorLabel="#7A7A7A"
                                        readOnly={!isSubmitting}
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="config.maxHeight"
                                        label="Maximum height(px)"
                                        helpText="Sets the maximum width of the preview. The aspect ratio will always remain the same. 
                    If the original asset fits within the supplied dimensions, 
                    the original width and height will be used for generating the rendition"
                                        colorLabel="#7A7A7A"
                                        readOnly={!isSubmitting}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="config.scale"
                                        label="Scale"
                                        colorLabel="#7A7A7A"
                                        readOnly={!isSubmitting}
                                        helpText="Scales the width and height of the rendition relative to the original size.
                     The scale is in percentages from 1 to 100. 
                     If used in combination with maxWidth or maxHeight the image will be scaled with a maximum size."
                                    />
                                </Col>
                                <Col sm="12" lg="6">
                                    <FastField
                                        component={InputField}
                                        name="config.validFor"
                                        label="Valid for(minutes)"
                                        colorLabel="#7A7A7A"
                                        readOnly={!isSubmitting}
                                        helpText="Amount of minutes the url is valid, specify -1 to obtain a url which is valid forever"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {values.config.format === "png" && (
                                    <Col sm="12" lg="6">
                                        <FastField
                                            component={InputField}
                                            type="color"
                                            name="config.background"
                                            label="Background"
                                            colorLabel="#7A7A7A"
                                            readOnly={!isSubmitting}
                                            disabled={!isSubmitting}
                                            helpText="Sets the background color (hex color code) for transparent images. 
                    If not specified, the output of PNG images will be transparent.”"
                                        />
                                    </Col>
                                )}
                                <Col sm="12" lg="6">
                                    {/* <FastField
                    component={InputField}
                    name='config.compression'
                    label='Compression'
                    colorLabel='#7A7A7A'
                    readOnly={!isSubmitting}
                  /> */}
                                </Col>
                            </Row>
                            <ButtonCustom
                                text="save"
                                type="submit"
                                disable={!isSubmitting}
                                outline={true}
                                fullWidth="auto"
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ContainerFormDownloadRenditions>
    );
}

export default memo(FormDownloadRenditions);
