import React, {  useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ClientsContext } from '../../../contexts/ClientsProvider';
import { InitContext } from '../../../contexts/InitProvider';
import { LoginContext } from '../../../contexts/LoginProvider'
import FormEditClient from '../../../components/Clients/FormEditClient'


EditClient.propTypes = {

};

function EditClient(props) {
	const { id } = useParams();
	const { user } = useContext(LoginContext);
	const { clientDetails, getClientDetails } = useContext(ClientsContext);
	const { getError } = useContext(InitContext)

	

	const getClient =  async () => {
		const res = await getClientDetails(id);
		if (!res.status) {
			getError(res?.messError)
			setTimeout(() => {
				getError(undefined)
			}, 3000);
		}
	}

	useEffect(() => {
		user && getClient();		
	}, [id,user])


	return (
		<>
			{clientDetails && <FormEditClient data={clientDetails} />}
		</>

	);
}

export default EditClient;